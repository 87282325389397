<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
      </div>
      <div>
        <iframe
            class="embedded-iframe"
            src="https://bankari-5047.slite.page/p/rqudD7yCuNvCEZ/Metodicka-karta-SLSP"
            frameborder="0"
            allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelperPopupSlsp",
  props: {
    type: String,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style lang="scss" scoped>
.content-panel{
    padding: 40px;
}

.embedded-iframe {
    width: 100%;
    height: 85vh;
    border-radius: 10px;
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .content-panel{
        padding: 20px;
    }
    .embedded-iframe{
        height: 80vh;
    }
}

.header-text{
    margin-bottom: 10px !important;
    min-height: none !important;
    margin-top: none !important;
}

</style>
