<template>
  <div class="result-block-broker-app p-relative">
    <div class="results-legend" :class="{'sticky': isSticky}" ref="resultNavbar" id="resultNavbar">
      <div
        class="break-40px"
      ></div>
      <!-- vyska uveru -->
      <div
        class="text-small wrap-100px flex-row-10px sort-button pointer"
        @click="sortOffersBy('pozicana_suma')"
      >
        <span>
          CELKOM
        </span>
        <img
          alt=""
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          :class="[ascending ? 'rotate-up display-sort filter-primary' : 'rotate-down display-sort filter-primary']"
        />
      </div>
      <!-- fixacia -->
      <maturity-fixation-choose-handler :rules="['priority']" :title="'FIXÁCIA'"/>
      <!-- sadzba  -->
      <div
        class="text-small wrap-50px flex-row-10px sort-button pointer"
        @click="sortOffersBy('sadzba')"
      >
        <span>
          SADZBA
        </span>
        <img
          alt=""
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          :class="[ascending ? 'rotate-up display-sort filter-primary' : 'rotate-down display-sort filter-primary']"
        />
      </div>
      <!-- splatka -->
      <div
        class="text-small wrap-100px flex-row-10px sort-button pointer"
        @click="sortOffersBy('mesacna_splatka')"
      >
        <span>
          SPLÁTKA
        </span>
        <img
          alt=""
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          :class="[ascending ? 'rotate-up display-sort filter-primary' : 'rotate-down display-sort filter-primary']"
        />
      </div>
      <!-- splatnost -->
      <div
        class="text-small wrap-50px flex-row-10px sort-button pointer"
        @click="sortOffersBy('splatnost_r')"
      >
        <span>
          SPLATNOST
        </span>
        <img
          alt=""
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          :class="[ascending ? 'rotate-up display-sort filter-primary' : 'rotate-down display-sort filter-primary']"
        />
      </div>
      <!-- poplatok -->
      <div
        class="text-small wrap-50px flex-row-10px sort-button pointer"
        @click="sortOffersBy('popl_sprac_hypo')"
      >
        <span>
          POPLATOK
        </span>
        <img
          alt=""
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          :class="[ascending ? 'rotate-up display-sort filter-primary' : 'rotate-down display-sort filter-primary']"
        />
      </div>
    </div>
    <div v-if="pickedApplications?.length !== 0">
      <mortgage-application-item-picked
        v-for="(application, applicationIndex) in pickedApplications"
        v-if="application.stage !== 'zrusena'"
        :key="applicationIndex"
        :application="application"
        :pre-picked="true"
      />
      <hr>
    </div>
    <div class="limits-loader" v-if="loading">
    <span class="blc-color MGright20px">prepočítavame možné ponuky financovania</span>
    <v-progress-circular
      class="loading-spinner small transparent"
      indeterminate
    ></v-progress-circular>
    </div>
    <mortgage-application-choice-item
      v-else
      v-for="(offer, offerIndex) in mortgageOffersData"
      :key="offer.bankKey"
      :offer="offer"
      :bank-key="offer.extra.bank_code_name"
      @handle-pin="handlePinOffer($event, offerIndex)"
    />
  </div>
</template>

<script>
import MortgageApplicationChoiceItem from "./MortgageApplicationChoiceItem";
import MaturityFixationChooseHandler
  from "../../../../shared/PopupHandler/Popups/MaturityFixationChoose/MaturityFixationChooseHandler";
import {mapGetters} from "vuex";
import MortgageApplicationItemPicked from "./MortgageApplicationItemPicked";
import {patchMortgage} from "@/services/brokerService";

export default {
  name: "MortgageApplicationsWrapper",
  components: {
    MortgageApplicationItemPicked,
    MaturityFixationChooseHandler,
    MortgageApplicationChoiceItem,
  },
  props: {
    mortgage: Object,
    mortgageOffers: Array,
  },
  computed: {
    ...mapGetters({
      offersDataGetter: "mortgageDetailState/getOffersData",
      offersLoading: "appState/getOffersLoading",
    }),
  },
  watch: {
    mortgageOffers (newApplications) {
      this.mortgageOffersData = this.getSortedOffers(newApplications, this.sortParameter, !this.ascending);
    },
    mortgage (newMortgage) {
      this.pickedApplications = newMortgage.applications;
    },
    offersLoading (value) {
      this.loading = value;
    },
    offersDataGetter () {
      this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
    },
  },
  created () {
    this.pickedApplications = this.mortgage.applications;
    this.mortgageOffersData = (JSON.parse(JSON.stringify({offers: this.mortgageOffers}))).offers;
    const sorter = setInterval(() => {
      if (!this.loading) {
        this.sortOffersBy("pozicana_suma");
        clearInterval(sorter);
      }
    }, 500);
  },
  mounted() {
    this.loading = this.$store.getters["appState/getOffersLoading"];
    this.navbarOffset = this.$refs.resultNavbar.offsetTop;
    // Add the scroll event listener
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    this.storePinnedToLocalStorage();
    if (!this.mortgageOffersData || this.mortgageOffersData.length === 0) return;
    this.createHistoryObject();
  },
  methods: {
    handleScroll() {
      // Set the 'isSticky' property based on the scroll position
      this.isSticky = window.scrollY > this.navbarOffset;
    },
    checkIfSequenceChanged(newOffers) {
      if (!this.mortgageOffersData) return true;
      const existingOffersLength = this.mortgageOffersData.length;
      if (existingOffersLength !== newOffers.length) return true;
      for (let i = 0; i < existingOffersLength; i++) {
        const bankOnIndexNew = newOffers[i].bank.code;
        const bankOnIndexOld = this.mortgageOffersData[i].bank.code;
        if (bankOnIndexNew !== bankOnIndexOld) return true;
      }
      return false;
    },
    getSortedOffers(offers, parameter, ascending) {
      let sortedOffers = JSON.parse(JSON.stringify({offers: offers})).offers;
      if (!sortedOffers) return;
      let pinnedArray = [];
      let unpinnedArray = [];
      for (const sortedOffer of sortedOffers) {
        if (sortedOffer.pinned) pinnedArray.push(sortedOffer);
        else unpinnedArray.push(sortedOffer);
      }
      if (ascending) {
        pinnedArray = pinnedArray.sort((a, b) => a[parameter] - b[parameter]);
        unpinnedArray = unpinnedArray.sort((a, b) => a[parameter] - b[parameter]);
      } else {
        pinnedArray = pinnedArray.sort((a, b) => b[parameter] - a[parameter]);
        unpinnedArray = unpinnedArray.sort((a, b) => b[parameter] - a[parameter]);
      }
      return [...pinnedArray, ...unpinnedArray];
    },
    sortOffersBy(parameter) {
      this.sortParameter = parameter;
      this.mortgageOffersData = this.getSortedOffers(this.mortgageOffersData, this.sortParameter, this.ascending);
      this.ascending = !this.ascending;
    },
    a() {
      setTimeout(() => {
        this.itemData = {
          img: './image.png',
          title: 'Item Title',
          body: 'Make beautiful, animated loading skeletons that automatically adapt to your app.'
        };
      }, 1500);
    },
    storePinnedToLocalStorage() {
      if (!this.mortgageOffersData) return;
      let pinnedApplications = [];
      for (const offer of this.mortgageOffersData) {
        if (offer.pinned) {
          pinnedApplications.push(offer);
        }
      }
      window.localStorage.removeItem("offers-pinned");
      window.localStorage.setItem("offers-pinned", JSON.stringify(pinnedApplications));
    },
    getBankVarsObject() {
      if (!this.mortgageOffersData) return;
      let banksVars = {};
      const banksKeys = ["mbank", "tatra", "unicredit", "slsp", "csob", "vub", "365", "prima"];
      for (const offer of this.mortgageOffersData) {
        if (offer.pinned) continue;
        banksVars[offer.extra.bank_code_name] = {
          splatnost: offer.splatnost_r ? offer.splatnost_r : 40,
        };
      }
      const banksIncluded = Object.keys(banksVars);
      for (const bankKey of banksKeys) {
        if (banksIncluded.includes(bankKey)) continue;
        banksVars[bankKey] = {
          splatnost: 40,
        }
      }
      return banksVars;
    },
    createHistoryObject() {
      const currentHistory = this.mortgage.offers_filter_history;
      let newHistoryObject = null;
      if (!currentHistory) {
        const applicationData = this.$store.getters["mortgageDetailState/getOffersData"];
        const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
        newHistoryObject = {
          sent: [],
          broker: {
            lastOffersSetup: this.getBankVarsObject(),
            history: [
              {
                fix: applicationData.fix ? applicationData.fix : 3,
                date: new Date().toISOString(),
                suma_bezucel: amounts.suma_bezucel ? amounts.suma_bezucel : 0,
                suma_nadobudnutie: amounts.suma_nadobudnutie ? amounts.suma_nadobudnutie : 0,
                suma_refinancovanie: amounts.suma_refinancovanie ? amounts.suma_refinancovanie : 0
              },
            ],
          },
          client: [],
        }
      } else {
        newHistoryObject = {
          sent: currentHistory.sent,
          client: currentHistory.client,
          broker: {
            lastOffersSetup: this.getBankVarsObject(),
            history:
              currentHistory.broker.history ? currentHistory.broker.history : Array.isArray(currentHistory.broker) && currentHistory.broker?.length !== 0 ? currentHistory.broker : [],
          }
        }
      }
      // console.log(newHistoryObject)
      patchMortgage({offers_filter_history: newHistoryObject}, this.mortgage.id);
    },
    // handleSadzbaChange (sadzba, key, index) {
    //   this.mortgageOffers[key][index].extra.sadzba = sadzba;
    // },
    // isPicked (application, bankKey) {
    //   const aBank = this.$store.getters["formStore/getBankByKey"](bankKey).id;
    //   const aFix = application.extra.fix;
    //   const aSplatnost = application.extra.splatnost_v_rokoch;
    //   const aSadzba = application.extra.sadzba;
    //   for (const prePicked of this.pickedApplications) {
    //     const pBank = prePicked.bank.id;
    //     const pFix = prePicked.fix;
    //     const pSadzba = prePicked.sadzba_pozadovana;
    //     const pSplatnost = prePicked.splatnost;
    //     // console.log({aBank, aFix, aSadzba, aSplatnost}, {pBank, pFix, pSadzba, pSplatnost});
    //     if (aBank === pBank && aSadzba === pSadzba && aFix === pFix && aSplatnost === pSplatnost) return true;
    //   }
    //   return false;
    // },
  },
  data: () => ({
    sortParameter: null,
    ascending: false,
    itemData: {},
    pinnedApplications: {},
    pickedApplications: [],
    mortgageOffersData: [],
    loading: false,
    isSticky: false,
    navbarOffset: 0
  }),
};
</script>

<style lang="scss" scoped>
.display-sort {
  display: none;
}

.rotate-up {
  transform: rotate(360deg);
  width: 20px;
  height: 20px;
}

.rotate-down {
  transform: rotate(-180deg);
  width: 20px;
  height: 20px;
}

.sort-button:hover {
  & .display-sort {
    display: inline-block;
    align-items: center;
  }
}

.result-block-broker-app {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 1000;
}
</style>
