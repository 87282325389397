import {
  getApplicationById,
  getClientById,
  getDebtDTI, getEmployerByID,
  getFlatById,
  getIncomeDTI,
  getMortgageApplicationForSpecificBank,
  getMortgageById,
  getMortgageLoanLimit,
  getMortgageMaturityLimit,
  getMortgageOffers,
  getPropertiesByIds,
  getPropertyById,
  patchClientDebt,
  patchClientFlat,
  patchClientIncome,
  patchClientProfile,
  patchClientProperty,
  patchMortgage,
  patchMortgageApplicationByID,
  postChildTypeToPropertyById,
  postClientDebt,
  postClientIncome,
  postClientProperty,
  postMonthlyIncome,
  removeClientDebt,
} from "@/services/brokerService";

const deepEqual = require("deep-equal");

export default {
  namespaced: true,
  state: {
    limitCorrection: 0,
    refreshMortgageAuto: true,
    limitsCaller: 0,
    offersCaller: 0,
    limitsChanged: false,
    previousLimitsChanged: false,
    mortgage: null,
    clientAmounts: {},
    applicant: {
      incomes: [],
      debts: [],
      clientObject: null,
    },
    coApplicant: {
      incomes: [],
      debts: [],
      clientObject: null,
      loading: false,
    },
    offers: null,
    appliedMortgageRateChanges: [],
    limits: null,
    maturity: null,
    amounts: {
      suma_nadobudnutie: null,
      suma_refinancovanie: null,
      suma_bezucel: null,
    },
    loanLimit: null,
    properties: {
      financedPropertiesIds: [],
      foundedPropertiesIds: [],
      relatedPropertiesIds: [],
      usedProperties: [],
      propertiesObjects: [],
    },
    offersData: {
      maximalna_splatnost: null,
      splatnost_v_rokoch: null,
      fix: null,
    },
    cache: {},
    // amountsLimits: {
    //   suma_nadobudnutie: null,
    //   suma_refinancovanie: null,
    //   suma_bezucel: null,
    // },
  },
  getters: {
    // getAmountsLimits: (state) => {
    //   return state.amountsLimits;
    // },
    getApplicationByStage: (state) => (stage) => {
      const applications = JSON.parse(JSON.stringify(state.mortgage.applications));
      return applications.filter(app => app.stage === stage);
    },
    getLimitCorrection: (state) => {
      return state.limitCorrection;
    },
    getClientAmounts: (state) => {
      return state.clientAmounts;
    },
    getLimitsChanged: (state) => {
      return state.limitsChanged;
    },
    isBankApplicationCreated: (state) => (bankId) => {
      let applications = JSON.parse(JSON.stringify(state.mortgage.applications));
      applications = applications.filter(a => a.stage !== "zrusena");
      const bankKeys = applications.map(a => a.bank.id);
      return bankKeys.includes(bankId);
    },
    getMaturity: (state) => {
      return state.maturity;
    },
    getClientIncomes: (state) => (type) => {
      return state[type].incomes;
    },
    getClientDebts: (state) => (type) => {
      return state[type].debts.filter((debt) => debt.refinancovany_hypotekou === null);
    },
    getRefinancedDebts: (state) => (id) => {
      const applicantDebts = state.applicant.debts.filter((debt) => debt.refinancovany_hypotekou === id);
      const coApplicantDebts = state.coApplicant?.debts.filter((debt) => debt.refinancovany_hypotekou === id);
      return [...applicantDebts, ...coApplicantDebts];
    },
    getAllIncomes: (state) => {
      return [...state.applicant.incomes, ...state.coApplicant?.incomes];
    },
    getAllDebts: (state) => {
      return [...state.applicant.debts, ...state.coApplicant?.debts];
    },
    getApplicantIncomes: (state) => {
      return state.applicant.incomes;
    },
    getApplicantDebts: (state) => {
      return state.applicant.debts;
    },
    getCoApplicantIncomes: (state) => {
      return state.coApplicant.incomes;
    },
    getCoApplicantDebts: (state) => {
      return state.applicant.debts;
    },
    // getApplicantDebts: (state) => {
    //   return state.applicant.debts;
    // },
    // getCoApplicantDebts: (state) => {
    //   return state.coApplicant.debts;
    // },
    getCoApplicantLoading: (state) => {
      return state.coApplicant.loading;
    },
    getMortgage: (state) => {
      return state.mortgage;
    },
    getAppliedMortgageRateChanges: (state) => {
      return state.appliedMortgageRateChanges;
    },
    getUserRelationToMortgage: (state) => (id) => {
      if (state.applicant.id === id) return "applicant";
      else if (state.coApplicant.id === id) return "coapplicant";
      return null;
    },
    getFullApplicant: (state) => {
      return state.applicant;
    },
    getFullCoApplicant: (state) => {
      return state.coApplicant;
    },
    getApplicant: (state) => {
      return state.applicant.clientObject;
    },
    getCoApplicant: (state) => {
      return state.coApplicant.clientObject;
    },
    getApplications: (state) => {
      return state.mortgage.applications;
    },
    getLoanLimit: (state) => {
      return state.loanLimit;
    },
    getOffers: (state) => {
      return state.offers;
    },
    getProperties: (state) => {
      return state.properties;
    },
    // getPropertiesByType: (state) => (type) => {
    //   return state.properties[type];
    // },
    getOffersData: (state) => {
      return state.offersData;
    },
    getLimits: (state) => {
      return state.limits;
    },
    getAmounts: (state) => {
      return state.amounts;
    },
    // getCachedState: (state) => {
    //   return state.cache;
    // },
    // isMortgageCached: (state) => (id) => {
    //   return id in state.cache;
    // },
  },
  mutations: {
    setPinnedApplication: (state, offerToPin) => {
      const offers = JSON.parse(JSON.stringify(state.offers));
      for (const [index, offer] of offers.entries()) {
        if (offer.extra.bank_code_name !== offerToPin.bank.code || offer.fix !== offerToPin.fix || offer.pinned !== offerToPin.pinned) continue;
        offers[index].pinned = !offerToPin.pinned;
        break;
      }
      state.offers = offers;
    },
    setLimitCorrection: (state, value) => {
      state.limitCorrection = value;
    },
    setClientAmounts: (state, values) => {
      state.clientAmounts = values;
    },
    setLimitsChanged: (state, value) => {
      state.previousLimitsChanged = state.limitsChanged;
      state.limitsChanged = value;
    },
    registerNewApplication: (state, application) => {
      const mortgage = JSON.parse(JSON.stringify(state.mortgage));
      mortgage.applications.push(application);
      state.mortgage = mortgage;
    },
    deleteApplication: (state, id) => {
      const mortgage = JSON.parse(JSON.stringify(state.mortgage));
      mortgage.applications = mortgage.applications.filter(application => application.id !== id);
      state.mortgage = mortgage;
    },
    setMaturityList: (state, maturityList) => {
      state.maturity = maturityList;
    },
    setBankOffer: (state, application) => {
      const offers = JSON.parse(JSON.stringify(state.offers));
      for (const [index, offer] of offers.entries()) {
        if (offer.extra.bank_code_name !== application.bankKey || offer.fix !== application.data.fix || offer.pinned !== application.data.pinned) continue;
        offers[index] = application.data;
        break;
      }
      state.offers = offers;
    },
    setBankOfferLoading: (state, loadingData) => {
      const offers = JSON.parse(JSON.stringify(state.offers));
      for (const offer of offers) {
        if (offer.extra.bank_code_name !== loadingData.bankKey || offer.fix !== loadingData.offerMeta.fix || offer.pinned !== loadingData.pinned) continue;
        offer.loadingSplatka = loadingData.loadingSplatka;
        offer.loadingSadzba = loadingData.loadingSadzba;
        break;
      }
      state.offers = offers;
    },
    setRefreshMortgageAuto: (state, value) => {
      state.refreshMortgageAuto = value;
    },
    patchApplication: (state, application) => {
      const mortgage = JSON.parse(JSON.stringify(state.mortgage));
      const index = mortgage.applications.map((e) => e.id).indexOf(application.id);
      const applicationCopy = JSON.parse(JSON.stringify(mortgage.applications[index]));
      mortgage.applications[index] = application;
      mortgage.applications[index].bank = applicationCopy.bank;
      state.mortgage = mortgage;
    },
    // setAmountsLimits: (state, limits) => {
    //   state.amountsLimits[limits.type] = limits.value;
    // },
    setClientIncomes: (state, incomesData) => {
      state[incomesData.client].incomes = incomesData.incomes;
    },
    patchClientIncome: (state, incomeData) => {
      const incomes = JSON.parse(JSON.stringify(state[incomeData.client].incomes));
      const index = incomes.map((x) => x.id).indexOf(incomeData.id);
      incomes[index] = {...incomes[index], ...incomeData.object};
      state[incomeData.client].incomes = incomes;
    },
    preRegisterClientIncome: (state, incomeData) => {
      const incomes = JSON.parse(JSON.stringify(state[incomeData.client].incomes));
      incomes.push(incomeData.object);
      state[incomeData.client].incomes = incomes;
    },
    postRegisterClientIncome: (state, incomeData) => {
      const incomes = JSON.parse(JSON.stringify(state[incomeData.client].incomes));
      const index = incomes.map((x) => x.id).indexOf(incomeData.fakeId);
      incomes.splice(index, 1, incomeData.object);
      state[incomeData.client].incomes = incomes;
    },
    setClientDebts: (state, debtData) => {
      state[debtData.client].debts = debtData.debts;
    },
    patchClientDebt: (state, debtData) => {
      const debts = JSON.parse(JSON.stringify(state[debtData.client].debts));
      const index = debts.map((x) => x.id).indexOf(debtData.id);
      debts[index] = {...debts[index], ...debtData.object};
      state[debtData.client].debts = debts;
    },
    preRegisterClientDebt: (state, debtData) => {
      if (debtData.client === "other") return;
      const debts = JSON.parse(JSON.stringify(state[debtData.client].debts));
      debts.push(debtData.object);
      state[debtData.client].debts = debts;
    },
    postRegisterClientDebt: (state, debtData) => {
      const debts = JSON.parse(JSON.stringify(state[debtData.client].debts));
      const index = debts.map((x) => x.id).indexOf(debtData.fakeId);
      debts.splice(index, 1, debtData.object);
      state[debtData.client].debts = debts;
    },
    deleteClientDebt: (state, debtData) => {
      if (debtData.clientRole === "other") return;
      const debts = JSON.parse(JSON.stringify(state[debtData.clientRole].debts));
      const index = debts.map((x) => x.id).indexOf(debtData.id);
      debts.splice(index, 1);
      state[debtData.clientRole].debts = debts;
    },
    toggleClientRefinanced: (state, debtData) => {
      const debts = JSON.parse(JSON.stringify(state[debtData.client].debts));
      const index = debts.map((x) => x.id).indexOf(debtData.id);
      debts[index].is_active = false;
      debts[index].refinancovany_hypotekou = debtData.refinancovany_hypotekou;
      state[debtData.client].debts = debts;
    },
    patchClientProfileObject: (state, clientData) => {
      const clientObject = JSON.parse(JSON.stringify(state[clientData.client].clientObject));
      clientObject.client_profile = {...clientObject.client_profile, ...clientData.object};
      state[clientData.client].clientObject = clientObject;
    },
    preRegisterProperty: (state, propertyData) => {
      const properties = JSON.parse(JSON.stringify(state.properties));
      if (propertyData.propertyType.druh === "zakladane_nehnutelnosti") {
        properties.foundedPropertiesIds.push(propertyData.object.id);
      } else if (propertyData.propertyType.druh === "financovane_nehnutelnosti") {
        properties.financedPropertiesIds.push(propertyData.object.id);
      }
      properties.propertiesObjects.push(propertyData.object);
      state.properties = properties;
    },
    postRegisterProperty: (state, propertyData) => {
      const properties = JSON.parse(JSON.stringify(state.properties));
      if (propertyData.propertyType.druh !== "zatazene_nehnutelnosti") {
        if (propertyData.propertyType.druh === "zakladane_nehnutelnosti") {
          const indexOfFakeId = properties.foundedPropertiesIds.map((x) => x.id).indexOf(propertyData.fakeId);
          properties.foundedPropertiesIds.splice(indexOfFakeId, 1, propertyData.object.id);
        } else if (propertyData.propertyType.druh === "financovane_nehnutelnosti") {
          const indexOfFakeId = properties.financedPropertiesIds.map((x) => x.id).indexOf(propertyData.fakeId);
          properties.financedPropertiesIds.splice(indexOfFakeId, 1, propertyData.object.id);
        }
      }
      const indexOfPropertyObject = properties.propertiesObjects.map((x) => x.id).indexOf(propertyData.fakeId);
      properties.propertiesObjects.splice(indexOfPropertyObject, 1, propertyData.object);
      properties.relatedPropertiesIds.push(propertyData.object.id);
      state.properties = properties;
    },
    patchProperty: (state, propertyData) => {
      const propertyObjects = JSON.parse(JSON.stringify(state.properties))
      const index = propertyObjects.propertiesObjects.map((x) => x.id).indexOf(propertyData.id);
      propertyObjects.propertiesObjects[index] = {...propertyObjects.propertiesObjects[index], ...propertyData.object};
      state.properties = propertyObjects
    },
    pickProperty: (state, propertyData) => {
      const propertiesObject = JSON.parse(JSON.stringify(state.properties));
      propertiesObject[propertyData.type].push(propertyData.id);
      state.properties = propertiesObject;
    },
    removeProperty: (state, propertyData) => {
      const propertyObjects = JSON.parse(JSON.stringify(state.properties))
      const index = propertyObjects[propertyData.type].indexOf(propertyData.id);
      propertyObjects[propertyData.type].splice(index, 1);
      state.properties = propertyObjects;
    },
    removeCoApplicant: (state) => {
      state.coApplicant = {
        incomes: [],
        debts: [],
        clientObject: null,
      }
    },
    patchMortgage: (state, mortgageData) => {
      let mortgage = JSON.parse(JSON.stringify(state.mortgage));
      mortgage = {...mortgage, ...mortgageData};
      state.mortgage = mortgage;
    },
    checkForObjectChange: (state, clientObject) => {
      const oldClient = JSON.parse(JSON.stringify(state[clientObject.type].clientObject));
      if (deepEqual(oldClient, clientObject.client, true)) return;
      state[clientObject.type].clientObject = clientObject.client;
    },
    // refreshMortgageDetailData: (state) => {
    //   state.mortgage = null;
    //   state.applicant = null;
    //   state.coApplicant = null;
    //   state.properties = {
    //     financedProperties: [],
    //     foundedProperties: [],
    //     unusedProperties: [],
    //     relatedProperties: [],
    //   };
    //   state.applications = {};
    //   state.appliedMortgageRateChanges = [];
    //   state.limits = {};
    //   state.amounts = {
    //     suma_nadobudnutie: null,
    //     suma_refinancovanie: null,
    //     suma_bezucel: null,
    //   };
    //   state.loanLimit = null;
    //   state.applicationsData = {
    //     splatnost_v_rokoch: null,
    //     fix: [],
    //   };
    //   state.cache = {};
    // },
    setAppliedMortgageRateChanges: (state, changes) => {
      state.appliedMortgageRateChanges = changes;
    },
    setLoanLimit: (state, limit) => {
      state.loanLimit = limit;
    },
    setMortgage: (state, mortgage) => {
      console.log("refreshed", new Date());
      state.mortgage = mortgage;
    },
    setApplicant: (state, applicant) => {
      delete applicant.incomes;
      delete applicant.debts;
      delete applicant.mortgages;
      state.applicant.clientObject = applicant;
    },
    setCoApplicant: (state, coApplicant) => {
      if (!coApplicant) return;
      delete coApplicant.incomes;
      delete coApplicant.debts;
      delete coApplicant.mortgages;
      state.coApplicant.clientObject = coApplicant;
    },
    setCoApplicantLoading: (state, value) => {
      state.coApplicant.loading = value;
    },
    setOffers: (state, offers) => {
      state.offers = offers;
    },
    setLimits: (state, limits) => {
      state.limits = limits;
    },
    setAmounts: (state, amounts) => {
      state.amounts = {...state.amounts, ...amounts};
    },
    setAmount: (state, amount) => {
      const amountsCopy = JSON.parse(JSON.stringify(state.amounts));
      amountsCopy[amount.type] = amount.value
      state.amounts = amountsCopy;
    },
    nullAmounts: (state) => {
      state.amounts = {
        suma_nadobudnutie: 0,
        suma_refinancovanie: 0,
        suma_bezucel: 0,
      }
    },
    setProperties: (state, propertiesInfo) => {
      let copyOfProperties = JSON.parse(JSON.stringify(state.properties));
      copyOfProperties.financedPropertiesIds = propertiesInfo.financedPropertiesIds;
      copyOfProperties.foundedPropertiesIds = propertiesInfo.foundedPropertiesIds;
      copyOfProperties.relatedPropertiesIds = propertiesInfo.relatedPropertiesIds;
      copyOfProperties.propertiesObjects = propertiesInfo.propertiesObjects;
      state.properties = copyOfProperties;
    },
    setPropertiesObjects: (state, propertiesInfo) => {
      let copyOfProperties = JSON.parse(JSON.stringify(state.properties));
      copyOfProperties.propertiesObjects = propertiesInfo.objects;
      copyOfProperties.relatedPropertiesIds = propertiesInfo.ids;
      state.properties = copyOfProperties;
    },
    setPropertiesIds: (state, propertiesInfo) => {
      let copyOfProperties = JSON.parse(JSON.stringify(state.properties));
      copyOfProperties[propertiesInfo.type] = propertiesInfo.ids;
      state.properties = copyOfProperties;
    },
    setOffersData: (state, offersData) => {
      if (deepEqual(state.offersData, offersData, true)) return;
      state.offersData = offersData;
    },
    // loadCache: (state, idOfCachedMortgage) => {
      //   state.mortgage = state.cache[idOfCachedMortgage].mortgage;
      //   state.applicant = state.cache[idOfCachedMortgage].applicant;
      //   state.coApplicant = state.cache[idOfCachedMortgage].coApplicant;
      // state.properties.foundedProperties =
      //   state.cache[idOfCachedMortgage].properties.foundedProperties;
      // state.properties.financedProperties =
      //   state.cache[idOfCachedMortgage].properties.financedProperties;
      // state.properties.unusedProperties =
      //   state.cache[idOfCachedMortgage].properties.unusedProperties;
      // state.properties.relatedProperties =
      //   state.cache[idOfCachedMortgage].properties.relatedProperties;
    // },
    setClientProfile: (state, data) => {
      let client = null;
      if (data.clientData.type === "Žiadateľ") {
        client = JSON.parse(JSON.stringify(state.applicant));
        client.client_profile = data.clientProfile;
        state.applicant = client;
      } else if (data.clientData.type === "Spolužiadateľ") {
        client = JSON.parse(JSON.stringify(state.coApplicant));
        client.client_profile = data.clientProfile;
        state.coApplicant = client;
      }
    },
    // patchClientIncome: (state, data) => {
    //   let client = null;
    //   if (data.clientData.type === "Žiadateľ") {
    //     client = JSON.parse(JSON.stringify(state.applicant));
    //     const index = client.incomes.map((x) => {
    //       return x.id;
    //     }).indexOf(data.incomeData.id);
    //     client.incomes[index] = data.incomeData;
    //     state.applicant = client;
    //   } else if (data.clientData.type === "Spolužiadateľ") {
    //     client = JSON.parse(JSON.stringify(state.coApplicant));
    //     const index = client.incomes.map((x) => {
    //       return x.id;
    //     }).indexOf(data.incomeData.id);
    //     client.incomes[index] = data.incomeData;
    //     state.coApplicant = client;
    //   }
    // },
    // patchClientDebt: (state, data) => {
    //   let client = null;
    //   if (data.clientData.type === "Žiadateľ") {
    //     client = JSON.parse(JSON.stringify(state.applicant));
    //     const index = client.debts.map((x) => {
    //       return x.id;
    //     }).indexOf(data.debtData.id);
    //     client.debts[index] = data.debtData;
    //     state.applicant = client;
    //   } else if (data.clientData.type === "Spolužiadateľ") {
    //     client = JSON.parse(JSON.stringify(state.coApplicant));
    //     const index = client.debts.map((x) => {
    //       return x.id;
    //     }).indexOf(data.debtData.id);
    //     client.debts[index] = data.debtData;
    //     state.coApplicant = client;
    //   }
    // },
    // patchPropertyObject: (state, data) => {
    //   const property = state.properties.propertiesObjects.find((prop) => prop.id === data.id);
    //   if (typeof property === "undefined") return;
    //   const index = state.properties.propertiesObjects.indexOf(property);
    //   state.properties[index] = data;
    // },
    nullState: (state) => {
      state.limitCorrection = 0;
      state.refreshMortgageAuto = true;
      state.limitsCaller = 0;
      state.limitsChanged = false;
      state.previousLimitsChanged = false;
      state.mortgage = null;
      state.clientAmounts = {};
      state.applicant = {
        incomes: [],
          debts: [],
          clientObject: null,
      };
      state.coApplicant = {
        incomes: [],
        debts:[],
        clientObject: null,
        loading: false,
      };
      state.offers = null;
      state.appliedMortgageRateChanges = [];
      state.limits = null;
      state.maturity = null;
      state.amounts = {
        suma_nadobudnutie: null,
        suma_refinancovanie: null,
        suma_bezucel: null,
      };
      state.loanLimit = null;
      state.properties = {
        financedPropertiesIds: [],
        foundedPropertiesIds: [],
        relatedPropertiesIds: [],
        propertiesObjects: [],
      };
      state.offersData = {
        maximalna_splatnost: null,
        splatnost_v_rokoch: null,
        fix: null,
      };
      state.cache = {};
    },
  },
  actions: {
    initializeMortgageDetailInfo: async ({dispatch, commit, state}, id) => {
      let mortgage = await getMortgageById(id,
        "applicant.incomes.mesacne_prijmy," +
        "applicant.debts," +
        "applicant.client_profile," +
        "coapplicant.incomes.mesacne_prijmy," +
        "coapplicant.debts," +
        "coapplicant.client_profile"
      );
      const applicant = mortgage.applicant;
      let coApplicant = null;
      if (mortgage.coapplicant) {
        coApplicant = mortgage.coapplicant;
      }
      commit("setAmounts", {
        suma_bezucel: mortgage.offers_filter_history?.broker?.history?.length > 0 ? mortgage.offers_filter_history.broker.history[0].suma_bezucel : mortgage.suma_bezucel,
        suma_refinancovanie: mortgage.offers_filter_history?.broker?.history?.length > 0 ? mortgage.offers_filter_history.broker.history[0].suma_refinancovanie : mortgage.suma_refinancovanie,
        suma_nadobudnutie: mortgage.offers_filter_history?.broker?.history?.length > 0 ? mortgage.offers_filter_history.broker.history[0].suma_nadobudnutie : mortgage.suma_nadobudnutie,
      });
      commit("setOffersData", {
        maximalna_splatnost: 40,
        fix: mortgage.offers_filter_history?.broker?.history?.length > 0 ? mortgage.offers_filter_history.broker.history[0].fix : 3,
      });
      commit("setClientAmounts", {
        suma_bezucel: mortgage.suma_bezucel,
        suma_refinancovanie: mortgage.suma_refinancovanie,
        suma_nadobudnutie: mortgage.suma_nadobudnutie,
      })
      commit("setAppliedMortgageRateChanges", mortgage.uplatnene_zmeny_sadzieb);
      mortgage.applications = await dispatch("fillMissingApplicationsData", mortgage.applications);
      const reducedMortgage = reduceMortgage(mortgage);
      commit("setMortgage", reducedMortgage);
      dispatch("fetchLimits", true);
      await dispatch("initializeProperties", mortgage);
      await dispatch("initializeClientIncomeDti", {incomes: applicant.incomes, role: "applicant"});
      await dispatch("initializeClientDebtDti", {debts: applicant.debts, role: "applicant"});
      await dispatch("initializeClientIncomeDti", {incomes: coApplicant?.incomes, role: "coApplicant"});
      await dispatch("initializeClientDebtDti", {debts: coApplicant?.debts, role: "coApplicant"});
      commit("setCoApplicant", coApplicant);
      commit("setApplicant", applicant);
      dispatch("mortgageDetailCloud/initializeClouds", null, {root: true});
    },
    fillMissingApplicationsData: async ({rootGetters}, pApplications) => {
      const banks = rootGetters["formStore/getBanks"];
      const banksIds = banks?.map((b) => b.id) || [];
      const isArrayOfNumbers = pApplications.every((pA) => typeof pA === "number");
      let applications;
      if (isArrayOfNumbers) {
        applications = await getMortgageApplications(pApplications, banks?.length > 0 ? "" : "banks");
      } else {
        applications = pApplications;
      }
      for (const application of applications) {
        const applicationData = {
          bank: typeof application.bank === "object" ? application.bank.id : application.bank,
          fix: application.fix,
          splatnost_m: application.splatnost * 12,
          suma_bezucel: application.suma_bezucel,
          suma_nadobudnutie: application.suma_nadobudnutie,
          suma_refinancovanie: application.suma_refinancovanie,
        }
        if (applicationData.suma_bezucel === 0 && applicationData.suma_nadobudnutie === 0 && applicationData.suma_refinancovanie === 0) continue;
        const retrievedOffer = await getMortgageApplicationForSpecificBank(application.mortgage, applicationData);
        application.backEndLimitCorrected = application.suma_refinancovanie + application.suma_nadobudnutie + application.suma_bezucel !== retrievedOffer.pozicana_suma;
        application.preplatenost = retrievedOffer.celkova_suma_s_urokmi;
        application.popl_za_vedenie_uctu = retrievedOffer.popl_za_vedenie_uctu;
        application.correctedAmount = retrievedOffer.pozicana_suma ? retrievedOffer.pozicana_suma : 0;
      }
      for (const application of applications) {
        const bankIndex = banksIds.indexOf(application.bank);
        if (bankIndex === -1) continue
        application.bank = banks[bankIndex];
      }
      return applications;
    },
    initializeClientIncomeDti: async ({commit}, clientData) => {
      const incomes = clientData.incomes;
      if (!incomes || !incomes.length > 0) return;
      for (const [index, income] of incomes.entries()) {
        if (typeof income.zamestnavatel === "number") {
          income.zamestnavatel = await getEmployerByID(income.zamestnavatel);
        }
        let dtiOfIncome = null;
        try {
          dtiOfIncome = await getIncomeDTI(income.id);
        } catch (e) {
        }
        const dtiInfo = pickHighestDti(dtiOfIncome, income.id);
        incomes[index] = {
          ...income,
          dti: dtiInfo.dtiMaxObject,
          error: dtiInfo.error,
          loading: false,
          clientInfo: {
            type: clientData.role === "applicant" ? "Žiadatel" : "Spolužiadatel",
            type_code: clientData.role,
          }
        }
      }
      commit("setClientIncomes", {incomes, client: clientData.role});
    },
    initializeClientDebtDti: async ({commit}, clientData) => {
      const debts = clientData.debts;
      if (!debts || !debts.length > 0) return;
      for (const [index, debt] of debts.entries()) {
        let dtiOfDebt = null;
        try {
          dtiOfDebt = await getDebtDTI(debt.id);
        } catch (e) {
        }
        const dtiInfo = pickHighestDti(dtiOfDebt);
        debts[index] = {
          ...debt,
          dti: dtiInfo.dtiMaxObject,
          error: dtiInfo.error,
          loading: false,
          clientInfo: {
            type: clientData.role === "applicant" ? "Žiadatel" : "Spolužiadatel",
            type_code: clientData.role,
          }
        }
      }
      commit("setClientDebts", {debts, client: clientData.role});
    },
    initializeProperties: async ({commit}, mortgage) => {
      if (mortgage.suvisiace_nehnutelnosti.length === 0) return;
      const propertiesInfo = {
        financedPropertiesIds: [],
        foundedPropertiesIds: [],
        relatedPropertiesIds: [],
        usedProperties: [],
        propertiesObjects: [],
      };
      const properties = (await getPropertiesByIds(mortgage.suvisiace_nehnutelnosti)).results;
      for (const property of properties) {
        let propertyObject = property;
        if (propertyObject.child_type === "flat") propertyObject = await getFlatById(propertyObject.id);
        const founded =
          mortgage.zakladane_nehnutelnosti.includes(propertyObject.id);
        const financed =
          mortgage.financovane_nehnutelnosti.includes(propertyObject.id);
        if (founded) {
          propertiesInfo.foundedPropertiesIds.push(propertyObject.id);
        }
        if (financed) {
          propertiesInfo.financedPropertiesIds.push(propertyObject.id);
        }
        propertiesInfo.relatedPropertiesIds.push(propertyObject.id);
        propertyObject.loading = false;
        propertiesInfo.propertiesObjects.push(propertyObject);
        if (!founded && !financed) continue;
        propertiesInfo.usedProperties.push(propertyObject.id);
      }
      // console.log(propertiesInfo);
      commit("setProperties", propertiesInfo);
      commit("appState/setLoadingPage", false, {root: true});
    },
    // retrieveProperties: async ({commit, state}) => {
      // const propertiesInfo = {
      //   financedPropertiesIds: [],
      //   foundedPropertiesIds: [],
      //   relatedPropertiesIds: [],
      //   propertiesObjects: [],
      // };
      // for (const propertyId of state.mortgage.suvisiace_nehnutelnosti) {
      //   let property = await getPropertyById(propertyId);
      //   if (property.child_type === "flat") property = await getFlatById(propertyId);
      //   const founded =
      //     state.mortgage.zakladane_nehnutelnosti.includes(propertyId);
      //   const financed =
      //     state.mortgage.financovane_nehnutelnosti.includes(propertyId);
      //   if (founded) {
      //     propertiesInfo.foundedPropertiesIds.push(propertyId);
      //   }
      //   if (financed) {
      //     propertiesInfo.financedPropertiesIds.push(propertyId);
      //   }
      //   propertiesInfo.relatedPropertiesIds.push(propertyId);
      //   propertiesInfo.propertiesObjects.push(property);
      // }
      // console.log(propertiesInfo);
      // commit("setProperties", propertiesInfo);
      // commit("setLoadingPage", false);
    // },
    // fetchClient: async ({commit, state}, clientInfo) => {
      // const client = await getClientById(clientInfo.id);
      // delete client.mortgages;
      // if (clientInfo.type === "Žiadateľ" && !deepEqual(state.applicant, client)) {
      //   commit("setApplicant", client);
      // } else if (clientInfo.type === "Spolužiadateľ" && !deepEqual(state.coApplicant, client)) {
      //   commit("setCoApplicant", client);
      // }
      // commit("setLoadingPage", false);
    // },
    fetchProperties: async ({dispatch, commit, state}) => {
      let propertiesChanged = false;
      const idsOfProperties = state.properties.relatedPropertiesIds;
      const properties = (await getPropertiesByIds(idsOfProperties)).results;
      for (const property of state.properties.propertiesObjects) {
        const propIndex = properties.map((p) => p.id).indexOf(property.id);
        const newPropModified = new Date(properties[propIndex].modified).getTime();
        const oldPropModified = new Date(property.modified).getTime();
        if (newPropModified === oldPropModified) continue;
        propertiesChanged = true;
        break;
      }
      if (!propertiesChanged) return;
      // commit("setLimitsChanged", true);
      dispatch("fetchLimits");
      commit("setPropertiesObjects", {objects: properties, ids: idsOfProperties});
    },
    fetchMortgage: async ({dispatch, commit, state}, mortgageData) => {
      if (!state.refreshMortgageAuto) return;
      let mortgage = await getMortgageById(state.mortgage.id,
        "applicant.incomes.mesacne_prijmy," +
        "applicant.debts," +
        "applicant.client_profile," +
        "coapplicant.incomes.mesacne_prijmy," +
        "coapplicant.debts," +
        "coapplicant.client_profile"
      );
      commit("setClientAmounts", {
        suma_bezucel: mortgage.suma_bezucel,
        suma_refinancovanie: mortgage.suma_refinancovanie,
        suma_nadobudnutie: mortgage.suma_nadobudnutie,
      })

      //opravene cez chatgpt 13_2_2025, hádzalo to tento error: Uncaught 
      //(in promise) TypeError: Cannot read properties of undefined (reading 'modified')
      const result = getNewApplications(state.mortgage.applications, mortgage.applications);
      if (!result) {
        console.error("getNewApplications returned undefined!");
        return;
      }
      
      const { missingApplications = [], oldApplications = [] } = result;
      
      if (missingApplications.length > 0) {
        let changedMortgageApplication = await dispatch("fillMissingApplicationsData", missingApplications);
        if (!Array.isArray(changedMortgageApplication)) {
          console.error("fillMissingApplicationsData did not return an array!", changedMortgageApplication);
          changedMortgageApplication = [];
        }
        changedMortgageApplication = [...changedMortgageApplication, ...oldApplications];
        state.mortgage.applications = changedMortgageApplication;
      } else if (state.mortgage.applications.length !== oldApplications.length) {
        state.mortgage.applications = oldApplications;
      }
      
      if (mortgageData && mortgageData.force) {
        mortgage = reduceMortgage(mortgage);
        mortgage.applications = (JSON.parse(JSON.stringify({applications: state.mortgage.applications}))).applications;
        if (!state.refreshMortgageAuto) return;
        commit("setMortgage", mortgage);
        return;
      }
      if (!deepEqual(state.appliedMortgageRateChanges, mortgage.uplatnene_zmeny_sadzieb)) {
        commit("setAppliedMortgageRateChanges", mortgage.uplatnene_zmeny_sadzieb);
      }
      if (!deepEqual(state.properties.relatedPropertiesIds, mortgage.suvisiace_nehnutelnosti, true)) {
        await dispatch("refreshRelatedProperties", mortgage.suvisiace_nehnutelnosti)
      }
      if (!deepEqual(state.properties.financedPropertiesIds, mortgage.financovane_nehnutelnosti, true)) {
        commit("setPropertiesIds", {type: "financedPropertiesIds", ids: mortgage.financovane_nehnutelnosti});
      }
      if (!deepEqual(state.properties.foundedPropertiesIds, mortgage.zakladane_nehnutelnosti, true)) {
        commit("setPropertiesIds", {type: "foundedPropertiesIds", ids: mortgage.zakladane_nehnutelnosti});
      }
      delete mortgage.applicant.mortgages;
      dispatch("checkClientChange", {client: mortgage.applicant, type: 'applicant'});
      if (mortgage.coapplicant) {
        delete mortgage.coapplicant.mortgages;
        dispatch("checkClientChange", {client: mortgage.coapplicant, type: 'coApplicant'});
      }
      mortgage = reduceMortgage(mortgage);
      // console.log(mortgage, state.mortgage);
      const applicationsCopy = (JSON.parse(JSON.stringify({applications: state.mortgage.applications}))).applications;
      delete state.mortgage.applications;
      delete mortgage.applications;
      if (!deepEqual(mortgage, state.mortgage, true)) {
        if (!state.refreshMortgageAuto) return;
        mortgage.applications = applicationsCopy;
        commit("setMortgage", mortgage);
      } else {
        if (missingApplications.length > 0 || applicationsCopy.length !== oldApplications.length) {
          if (!state.refreshMortgageAuto) return;
          mortgage.applications = applicationsCopy;
          commit("setMortgage", mortgage);
        } else {
          state.mortgage.applications = applicationsCopy;
        }
      }
    },
    checkClientChange: async ({dispatch, commit}, clientObject) => {
      const incomes = clientObject.client.incomes;
      const debts = clientObject.client.debts;
      delete clientObject.client.incomes;
      delete clientObject.client.debts;
      dispatch("checkForIncomesChange", {client: clientObject.type, incomes});
      dispatch("checkForDebtsChange", {client: clientObject.type, debts});
      commit("checkForObjectChange", clientObject);
    },
    checkForIncomesChange: async ({commit, state}, incomesObject) => {
      let incomesChanged = false;
      const oldIncomes = state[incomesObject.client].incomes;
      const newIncomes = [];
      for (const oldIncome of oldIncomes) {
        const newIncomeIndex = incomesObject.incomes.map((i) => i.id).indexOf(oldIncome.id);
        if (newIncomeIndex === -1) {
          incomesChanged = true;
        } else {
          const newIncome = incomesObject.incomes[newIncomeIndex];
          if (deepEqual(newIncome, oldIncome, true)) {
            newIncomes.push(oldIncome);
            continue;
          }
          if (typeof newIncome.zamestnavatel === "number") {
            newIncome.zamestnavatel = await getEmployerByID(newIncome.zamestnavatel);
          }
          incomesChanged = true;
          let dtiOfIncome = 0;
          try {
            dtiOfIncome = await getIncomeDTI(newIncome.id);
          } catch (e) {
          }
          const dtiInfo = pickHighestDti(dtiOfIncome);
          newIncomes.push({
            ...newIncome,
            dti: dtiInfo.dtiMaxObject,
            error: dtiInfo.error,
            loading: false,
            clientInfo: {
              type: incomesObject.client === "applicant" ? "Žiadatel" : "Spolužiadatel",
              type_code: incomesObject.client,
            }
          });
        }
      }
      if (!incomesChanged) return;
      commit("setClientIncomes", {incomes: newIncomes, client: incomesObject.client});
    },
    checkForDebtsChange: async ({commit, state}, debtsObject) => {
      let debtsChanged = false;
      const oldDebts = state[debtsObject.client].debts;
      const newDebts = [];
      for (const oldDebt of oldDebts) {
        const newDebtIndex = debtsObject.debts.map((i) => i.id).indexOf(oldDebt.id);
        if (newDebtIndex === -1) {
          debtsChanged = true;
        } else {
          const newDebt = debtsObject.debts[newDebtIndex];
          if (deepEqual(newDebt, oldDebt, true)) {
            newDebts.push(oldDebt);
            continue;
          }
          debtsChanged = true;
          let dtiOfDebt = 0;
          try {
            dtiOfDebt = await getDebtDTI(newDebt.id);
          } catch (e) {
          }
          const dtiInfo = pickHighestDti(dtiOfDebt);
          newDebts.push({
            ...newDebt,
            dti: dtiInfo.dtiMaxObject,
            error: dtiInfo.error,
            loading: false,
            clientInfo: {
              type: debtsObject.client === "applicant" ? "Žiadatel" : "Spolužiadatel",
              type_code: debtsObject.client,
            }
          });
        }
      }
      if (!debtsChanged) return;
      commit("setClientDebts", {debts: newDebts, client: debtsObject.client});
    },
    setMortgageApplicationStage: async ({commit, state}, {id, newStage}) => {
      const indexOfEditedApplication = state.mortgage.applications.map((app) => app.id).indexOf(id);
      if (indexOfEditedApplication === -1) return;
      const application = JSON.parse(JSON.stringify(state.mortgage.applications[indexOfEditedApplication]));
      const applicationPatchData = {
        stage_old: application.stage,
        stage: newStage,
      }
      const patchedApplication = await patchMortgageApplicationByID(id, applicationPatchData);
      commit("patchApplication", patchedApplication);
    },
    patchMortgageApplication: async ({commit}, {id, applicationPatchData}) => {
      if (!id) return;
      const patchedApplication = await patchMortgageApplicationByID(id, applicationPatchData);
      commit("patchApplication", patchedApplication);
    },
    refreshRelatedProperties: async ({commit}, propertiesIds) => {
      const properties = [];
      for (const propertyId of propertiesIds) {
        let property = await getPropertyById(propertyId);
        if (property.child_type === "flat") property = await getFlatById(propertyId);
        properties.push(property);
      }
      commit("setPropertiesObjects", {objects: properties, ids: propertiesIds});
    },
    fetchOffer: async ({commit, rootGetters}, applicationData) => {
      commit("setBankOfferLoading", applicationData);
      const fetchedOffer =
          await getMortgageApplicationForSpecificBank(applicationData.mortgageId, applicationData.offerMeta);
      const banksInfo = rootGetters["formStore/getBanks"]
        .filter((bankInfo) => bankInfo.id !== "other")
        .map((bankInfo) => {
          return {code: bankInfo.code_name, id: bankInfo.id, name: bankInfo.nazov, logo: bankInfo.logo_square}
        })
      const bankIndex = banksInfo.map((bank) => bank.code).indexOf(fetchedOffer.extra.bank_code_name);
      if (bankIndex !== -1) {
        fetchedOffer.bank = banksInfo[bankIndex];
      }
      commit("setBankOffer", {
        data: {...fetchedOffer, loadingSplatka: false, loadingSadzba: false, pinned: applicationData.pinned},
        bankKey: applicationData.bankKey
      });
    },
    fetchOffers: async({commit, rootGetters, state}) => {
      state.offersCaller++
      const call = state.offersCaller;
      commit("appState/setOffersLoading", true, {root: true});
      // console.trace();
      const amountData = state.amounts;
      // console.log(amountData);
      if (amountData.suma_bezucel === 0 && amountData.suma_refinancovanie === 0 && amountData.suma_nadobudnutie === 0) {
        commit("setOffers", null);
        commit("appState/setOffersLoading", false, {root: true});
        return;
      }
      const offersData = state.offersData;
      const banksInfo = rootGetters["formStore/getBanks"]
        .filter((bankInfo) => bankInfo.id !== "other")
        .map((bankInfo) => {
          return {code: bankInfo.code_name, id: bankInfo.id, name: bankInfo.nazov, logo: bankInfo.logo_square}
        })
      if (call !== state.offersCaller) return;
      // console.log(banksInfo);
      const banksMaturityArrays = getMaturityArrays(state.offers, state.mortgage.offers_filter_history?.broker?.lastOffersSetup, offersData, banksInfo);
      // console.log(banksMaturityArrays)
      const offers = await getOffersByMaturity(state.mortgage.id, banksMaturityArrays, amountData, offersData.fix);
      // console.log(JSON.parse(JSON.stringify(offers)));
      const mappedOffers = mapOffersByMaturity(offers, banksMaturityArrays, banksInfo);
      // console.log(mappedOffers);
      const applicationsStored = JSON.parse(window.localStorage.getItem("offers-pinned"));
      const mergedOffers = mergePinnedToOffers(mappedOffers, applicationsStored, banksInfo);
      // console.log(JSON.parse(JSON.stringify(mergedOffers)));
      const banksCodeNames = banksInfo.map((b) => b.code);
      for (const offer of mergedOffers) {
        const bankIndex = banksCodeNames.indexOf(offer.extra.bank_code_name);
        if (bankIndex === -1) continue
        offer.bank = banksInfo[bankIndex];
      }
      if (call !== state.offersCaller) return;
      commit("setOffers", mergedOffers);
      commit("appState/setOffersLoading", false, {root: true});
    },
    fetchOffersByBank: async ({dispatch, state, rootGetters}, offersData) => {
      const amounts = rootGetters["mortgageDetailState/getAmounts"];
      const offers = state.offers.filter((offer) => offer.bank.code === offersData.bankKey);
      for (const offer of offers) {
        const specificOfferData = {
          ...offersData,
          pinned: offer.pinned,
          offerMeta: {
            ...amounts,
            bank: offer.bank.id,
            fix: offer.fix,
            splatnost_m: offer.splatnost_r * 12
          }
        }
        dispatch("fetchOffer", specificOfferData);
      }
    },
    fetchLimits: async ({dispatch, commit, state}, init=false) => {
      // commit("appState/setLimitsLoader", false, {root: true});
      const call = state.limitsCaller;
      const fix = state.offersData.fix;
      const amounts = state.amounts;
      if (!init) {
        state.mortgage.applications = await dispatch("fillMissingApplicationsData", state.mortgage.applications);
      }
      const purpose = getMortgagePurpose(amounts, state.mortgage.ciel_prva_analyza);
      const maturityList = await getMortgageMaturityLimit(state.mortgage.id, fix);
      commit("setMaturityList", maturityList);
      const maxMaturity = getMaxMaturity(maturityList, purpose);
      commit("setOffersData", {...state.offersData, maximalna_splatnost: maxMaturity});
      const limits = await getMortgageLoanLimit(state.mortgage.id, fix);
      if (call !== state.limitsCaller) {
        return;
      }
      state.limitsCaller++;
      if (deepEqual(state.limits, limits, true)) {
        // commit("appState/setLimitsLoader", true, {root: true});
        return;
      }
      commit("setLimits", limits);
      commit("appState/setLimitsLoader", true, {root: true});
    },
    assignMonthlyIncomesToIncome: async ({dispatch}, data) => {
      const incomes = [];
      for (const incomeData of data.incomes) {
        const income = {
          ...incomeData,
          income: data.id,
        };
        incomes.push(await postMonthlyIncome(income));
      }
      return incomes;
    },
    toggleClientIncome: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      try {
        // commit("setLimitsChanged", false);
        commit("patchClientIncome", {client: data.clientRole, id: data.id, object: {is_active: data.isActive}})
        commit("appState/setSuccessMessage", {
          text: `${data.clientLabel}ov príjem bol úspešne vybratý`,
          type: "success"
        }, {root: true});
        await patchClientIncome({is_active: data.isActive}, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchClientIncome", {client: data.clientRole, id: data.id, object: {is_active: data.isActive}})
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovho príjmu nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    toggleClientDebt: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      try {
        // commit("setLimitsChanged", false);
        commit("patchClientDebt", {client: data.clientRole, id: data.id, object: {is_visible: data.isActive}});
        commit("appState/setSuccessMessage", {
          text: `${data.clientLabel}ov výdavku bol úspešne vybratý`,
          type: "success"
        }, {root: true});
        await patchClientDebt({is_visible: data.isActive}, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchClientDebt", {client: data.clientRole, id: data.id, object: {is_visible: !data.isActive}})
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovho výdavku nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    toggleClientRefinanced: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const debtBackup = state[data.clientRole].debts.find((debt) => data.id === debt.id);
      try {
        // commit("setLimitsChanged", false);
        commit("patchClientDebt", {client: data.clientRole, id: data.id, object: data.object});
        commit("appState/setSuccessMessage", {
          text: `${data.clientLabel}ov dlh bol úspešne vybratý ako refinancovaný dlh`,
          type: "success"
        }, {root: true});
        await patchClientDebt(data.object, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchClientDebt", {client: data.clientRole, id: data.id, object: debtBackup});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovho refinancovaného dlhu nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    patchClientProfileObject: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const clientProfileObjectBackup = state[data.clientRole].clientObject.client_profile;
      try {
        // commit("setLimitsChanged", false);
        commit("patchClientProfileObject", {client: data.clientRole, object: data.object});
        commit("appState/setSuccessMessage", {
          text: `${data.clientLabel}ova bonita bola aktualizovaná`,
          type: "success"
        }, {root: true});
        await patchClientProfile(data.object, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchClientProfileObject", {client: data.clientRole, object: clientProfileObjectBackup});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovej bonity nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    patchClientIncome: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const incomeBackup = JSON.parse(JSON.stringify(state[data.clientRole].incomes.find((income) => income.id === data.id)));
      try {
        // commit("setLimitsChanged", false);
        data.object.loading = true;
        data.object.dti = 0;
        data.object.error = null;
        commit("patchClientIncome", {client: data.clientRole, id: data.id, object: data.object})
        commit("appState/setSuccessMessage", {
          text: `${data.clientLabel}ov príjem bol úspešne upravený`,
          type: "success"
        }, {root: true});
        await patchClientIncome(data.object, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
        const dtiInfo = pickHighestDti(await getIncomeDTI(data.id))
        commit("patchClientIncome", {
          client: data.clientRole,
          id: data.id,
          object: {loading: false, dti: dtiInfo.dtiMaxObject, error: dtiInfo.error,}
        });
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchClientIncome", {client: data.clientRole, id: data.id, object: incomeBackup});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovho príjmu nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    patchClientDebt: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      let debtBackup = null;
      if (data.clientRole !== "other") {
        debtBackup = JSON.parse(JSON.stringify(state[data.clientRole].debts.find((debt) => debt.id === data.id)));
      }
      try {
        // commit("setLimitsChanged", false);
        data.object.loading = true;
        data.object.error = null;
        if (data.clientRole !== "other") {
          commit("patchClientDebt", {client: data.clientRole, id: data.id, object: data.object})
        }
        if (data.clientLabel) {
          commit("appState/setSuccessMessage", {
            text: `${data.clientLabel}ov dlh bol úspešne upravený`,
            type: "success"
          }, {root: true});
        }
        await patchClientDebt(data.object, data.id);
        if (data.clientRole !== "other") {
          // commit("setLimitsChanged", true);
          dispatch("fetchLimits");
          const dtiInfo = pickHighestDti(await getDebtDTI(data.id));
          commit("patchClientDebt", {
            client: data.clientRole,
            id: data.id,
            object: {loading: false, dti: dtiInfo.dtiMaxObject, error: dtiInfo.error,}
          });
        }
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        if (data.clientRole !== "other") {
          commit("patchClientDebt", {client: data.clientRole, id: data.id, object: debtBackup});
          commit("appState/setSuccessMessage", {
            text: `Pri ukladaní ${data.clientLabel.toLowerCase()}ovho dlhu nastala chyba`,
            type: "error"
          }, {root: true});
        }
      }
      commit("setRefreshMortgageAuto", true);
    },
    pickProperty: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      try {
        // commit("setLimitsChanged", false);
        commit("pickProperty", {id: data.propertyId, type: data.propertyType});
        commit("appState/setSuccessMessage", {
          text: `Nehnuteľnosť bola úspešne vybratá ako ${data.propertyLabel.toLowerCase()}`,
          type: "success"
        }, {root: true});
        if (state.properties.foundedPropertiesIds.length === 0) {
          data.object.zakladane_nehnutelnosti = [data.propertyId];
          commit("pickProperty", {id: data.propertyId, type: "foundedPropertiesIds"});
        }
        await patchMortgage(data.object, data.mortgageId);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("removeProperty", {id: data.propertyId, type: data.propertyType});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní výberu nehnuteľnosti nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    removeProperty: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      try {
        // commit("setLimitsChanged", false);
        commit("removeProperty", {id: data.propertyId, type: data.propertyType});
        commit("appState/setSuccessMessage", {
          text: `Nehnuteľnosť bola úspešne odobratá`,
          type: "success"
        }, {root: true});
        await patchMortgage(data.object, data.mortgageId);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("pickProperty", {id: data.propertyId, type: data.propertyType});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní odobratia nehnuteľnosti nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    patchProperty: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const propertiesBackup = JSON.parse(JSON.stringify(state.properties.propertiesObjects.find((prop) => prop.id === data.id)));
      try {
        commit("setLimitsChanged", false);
        if (data.object.tarcha_orientacny_zostatok) {
          if (!data.object.meta || !data.object.meta.hasOwnProperty("tarcha_debt")) {
            const newDebtObject = {
              clientRole: data.object.majitelType,
              object: {
                klient: data.object.majitel,
                typ: "hypoteka",
                zostatok: data.object.tarcha_orientacny_zostatok,
                banka: data.object.tarcha_banka,
              },
            }
            const id = await dispatch("createClientDebt", newDebtObject);
            data.object.meta = {
              ...data.object.meta,
              tarcha_debt: {
                id: id,
                owner: data.object.majitelType,
              }
            }
          } else {
            const isOwnerApplicant = state.applicant.clientObject.id === data.object.majitel;
            const isOwnerCoApplicant = state.coApplicant?.clientObject?.id === data.object.majitel;
            let debtData = {
              id: data.object.meta.tarcha_debt.id,
              object: {
                zostatok: data.object.tarcha_orientacny_zostatok,
                banka: data.object.tarcha_banka,
              }
            };
            if (isOwnerApplicant) {
              debtData.clientRole = "applicant";
            } else if (isOwnerCoApplicant) {
              debtData.clientRole = "coApplicant";
            } else if (!isOwnerApplicant && !isOwnerCoApplicant) {
              debtData.clientRole = "other";
            }
            if (data.object.tarcha_orientacny_zostatok) {
              if (data.object.meta.tarcha_debt.owner !== debtData.clientRole) {
                dispatch("deleteClientDebt", {id: data.object.meta.tarcha_debt.id, clientRole: data.object.meta.tarcha_debt.owner})
                const newDebtObject = {
                  clientRole: data.object.majitelType,
                  object: {
                    klient: data.object.majitel,
                    typ: "hypoteka",
                    zostatok: data.object.tarcha_orientacny_zostatok,
                    banka: data.object.tarcha_banka,
                  },
                }
                const id = await dispatch("createClientDebt", newDebtObject);
                data.object.meta = {
                  ...data.object.meta,
                  tarcha_debt: {
                    id: id,
                    owner: data.object.majitelType,
                  }
                }
              } else {
                dispatch("patchClientDebt", debtData)
              }
            }
          }
        }
        commit("patchProperty", {id: data.id, object: data.object})
        commit("appState/setSuccessMessage", {
          text: `${data.propertyLabel} nehnuteľnosť bola úspešne upravená`,
          type: "success"
        }, {root: true});
        await patchClientProperty(data.object, data.id);
        // commit("setLimitsChanged", true);
        dispatch("fetchLimits");
      } catch (e) {
        commit("setLimitsChanged", state.previousLimitsChanged);
        commit("patchProperty", {id: data.id, object: propertiesBackup});
        commit("appState/setSuccessMessage", {
          text: `Pri ukladaní nehnuteľnosti nastala chyba`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    createClientIncome: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      // commit("setLimitsChanged", false);
      const fakeId = Math.floor((Math.random() * 10000) * -1);
      const object = {
        druh: data.object.druh,
        id: fakeId,
        loading: true,
        is_active: true,
      }
      commit("preRegisterClientIncome", {client: data.clientRole, object});
      const clientIncome = await postClientIncome(data.object);
      let monthlyIncomes = [];
      if (
        [
          "pracovny_pomer_na_cas_urcity",
          "pracovny_pomer_na_neurcito",
          "dohoda_o_pracovnej_cinnosti",
          "pracovny_pomer_vo_vlastnej_firme",
          "pracovny_pomer_cez_agenturu",
        ].includes(data.object.druh)
      ) {
        //#TODO: preveriť potrebnosť clientInfo
        monthlyIncomes = await dispatch("assignMonthlyIncomesToIncome", {
          incomes: data.monthlyIncomes,
          id: clientIncome.id,
          clientInfo: data.clientInfo,
        });
      }
      // commit("setLimitsChanged", true);
      dispatch("fetchLimits");
      clientIncome.mesacne_prijmy = monthlyIncomes;
      const dtiInfo = pickHighestDti(await getIncomeDTI(clientIncome.id));
      const incomeObject = {
        client: data.clientRole,
        object: clientIncome,
        fakeId: fakeId,
      }
      incomeObject.object.dti = dtiInfo.dtiMaxObject;
      incomeObject.object.error = dtiInfo.error;
      incomeObject.object.loading = false;
      commit("postRegisterClientIncome", incomeObject);
      commit("setRefreshMortgageAuto", true);
    },
    createClientDebt: async ({dispatch, commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      // commit("setLimitsChanged", false);
      const fakeId = Math.floor((Math.random() * 10000) * -1);
      const object = {
        typ: data.object.typ,
        id: fakeId,
        loading: true,
        is_active: true,
        banka: data.object.banka,
        refinancovany_hypotekou: null,
      }
      commit("preRegisterClientDebt", {client: data.clientRole, object});
      const clientDebt = await postClientDebt(data.object);
      if (data.clientRole === "other") {
        commit("setRefreshMortgageAuto", true);
        return clientDebt.id;
      }
      // commit("setLimitsChanged", true);
      dispatch("fetchLimits");
      const dtiInfo = pickHighestDti(await getDebtDTI(clientDebt.id));
      const debtObject = {
        client: data.clientRole,
        object: clientDebt,
        fakeId: fakeId,
      };
      debtObject.object.dti = dtiInfo.dtiMaxObject;
      debtObject.object.error = dtiInfo.error;
      debtObject.object.loading = false;
      commit("postRegisterClientDebt", debtObject);
      commit("setRefreshMortgageAuto", true);
      return new Promise((resolve) => {
        if (clientDebt.id) resolve(clientDebt.id);
      })
    },
    createProperty: async ({dispatch, commit, state}, propertyInfo) => {
      commit("setRefreshMortgageAuto", false);
      // commit("setLimitsChanged", false);
      const fakeId = Math.floor((Math.random() * 10000) * -1);
      const object = {
        id: fakeId,
        loading: true,
        address_text_city: propertyInfo.object.address_text_city,
        address_text_street: propertyInfo.object.address_text_street,
        address_text_number: propertyInfo.object.address_text_number,
      }
      if (propertyInfo.object.tarcha_orientacny_zostatok) {
        const newDebtObject = {
          clientRole: propertyInfo.object.majitelType,
          object: {
            klient: propertyInfo.object.majitel,
            typ: "hypoteka",
            zostatok: propertyInfo.object.tarcha_orientacny_zostatok,
            banka: propertyInfo.object.tarcha_banka,
          },
        }
        const id = await dispatch("createClientDebt", newDebtObject);
        propertyInfo.object.meta = {
          tarcha_debt: {
            id: id,
            owner: propertyInfo.object.majitelType,
          }
        }
      }
      commit("preRegisterProperty", {propertyType: propertyInfo.propertyType, object});
      let property = await postClientProperty(propertyInfo.object);
      let childType = propertyInfo.propertyType.child_type;
      if (childType === "flatInFlat") childType = "flat"
      else if (childType === "flatInHouse") childType = "flat"
      await postChildTypeToPropertyById({type: childType}, property.id);
      if (propertyInfo.propertyType.child_type === "flatInFlat") {
        property = await patchClientFlat({typ_bytu: "v_bytovom_dome"}, property.id);
      } else if (propertyInfo.propertyType.child_type === "flatInHouse") {
        property = await patchClientFlat({typ_bytu: "v_rodinnom_dome"}, property.id);
      }
      // commit("setLimitsChanged", true);
      dispatch("fetchLimits");
      const propertyObject = {
        fakeId: fakeId,
        propertyType: propertyInfo.propertyType,
        object: {...property, loading: false},
      }
      const mortgage = {};
      commit("postRegisterProperty", propertyObject);
      if (propertyInfo.propertyType.druh === "zakladane_nehnutelnosti") {
        mortgage["zakladane_nehnutelnosti"] = state.properties.foundedPropertiesIds;
      } else if (propertyInfo.propertyType.druh === "financovane_nehnutelnosti") {
        mortgage["financovane_nehnutelnosti"] = state.properties.financedPropertiesIds;
      } else if (propertyInfo.propertyType.druh === "zatazene_nehnutelnosti") {
        mortgage["suvisiace_nehnutelnosti"] = state.properties.relatedPropertiesIds;
      }
      await patchMortgage(mortgage, propertyInfo.mortgageId);
      commit("setRefreshMortgageAuto", true);
    },
    deleteClientDebt: async ({dispatch, commit}, debtInfo) => {
      removeClientDebt(debtInfo.id);
      commit("deleteClientDebt", debtInfo);
    },
    // deleteClientIncome: async ({dispatch, commit}, incomeInfo) => {
    //   commit("setLoadingPage", true);
    //   await removeClientIncome(incomeInfo.id);
    //   await dispatch("fetchClient", incomeInfo.clientInfo);
    // },
    patchMortgage: async ({dispatch, commit, state}, mortgageData) => {
      commit("setRefreshMortgageAuto", false);
      const mortgageBackUp = JSON.parse(JSON.stringify(state.mortgage));
      try {
        if (mortgageData.object.hasOwnProperty("stage")) {
          const mortgageStates = [
            "zrusena",
            "vyber_riesenia",
            "podpis_ziadosti",
            "kompletizacia_podkladov",
            "pozastavena",
            "schvalovanie",
            "servis"
          ];
          const newStageIndex = mortgageStates.indexOf(mortgageData.object.stage);
          const oldStageIndex = mortgageData.object.stage_old ? mortgageStates.indexOf(mortgageData.object.stage_old) : 0;
          if (newStageIndex === -1 || oldStageIndex === -1 || oldStageIndex >= newStageIndex) {
            commit("setRefreshMortgageAuto", true);
            return;
          }
        }
        commit("patchMortgage", mortgageData.object);
        const mortgage = reduceMortgage(await patchMortgage(mortgageData.object, mortgageData.id));
        mortgage.applications = state.mortgage.applications;
        commit("setMortgage", mortgage);
      } catch (e) {
        commit("setMortgage", mortgageBackUp);
      }
      commit("setRefreshMortgageAuto", true);
    },
    removeApplication: async ({commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const mortgageBackup = JSON.parse(JSON.stringify(state.mortgage));
      try {
        commit("patchApplication", data.removedApplication);
        commit("appState/setSuccessMessage", {
          text: "Žiadosť bola zrušená",
          type: "success"
        }, {root: true});
        await patchMortgageApplicationByID(data.removedApplication.id, {...data.removedApplication, bank: data.removedApplication.bank.id});
      } catch (e) {
        commit("appState/setSuccessMessage", {
          text: "Pri rušení žiadosti nastala chyba",
          type: "error"
        }, {root: true});
        commit("setMortgage", mortgageBackup);
      }
      commit("setRefreshMortgageAuto", true);
    },
    restoreApplication: async ({commit, state}, data) => {
      commit("setRefreshMortgageAuto", false);
      const mortgageBackup = JSON.parse(JSON.stringify(state.mortgage));
      try {
        commit("patchApplication", data.restoredApplication);
        commit("appState/setSuccessMessage", {
          text: "Žiadosť bola obnovená",
          type: "success"
        }, {root: true});
        await patchMortgageApplicationByID(data.restoredApplication.id, {...data.restoredApplication, bank: data.restoredApplication.bank.id});
      } catch (e) {
        commit("appState/setSuccessMessage", {
          text: "Pri obnovovaní žiadosti nastala chyba",
          type: "error"
        }, {root: true});
        commit("setMortgage", mortgageBackup);
      }
      commit("setRefreshMortgageAuto", true);
    },
    patchClientProfile: async ({dispatch, commit, state}, savedClientObject) => {
      commit("setRefreshMortgageAuto", false);
      commit("appState/setLoadingPage", true, {root: true});
      let clientBackup = null;
      if (savedClientObject.clientInfo.type) {
        if (savedClientObject.clientInfo.type === "Žiadateľ") {
          clientBackup = JSON.parse(JSON.stringify(state.applicant))
        } else if (savedClientObject.clientInfo.type === "Spolužiadateľ") {
          clientBackup = JSON.parse(JSON.stringify(state.coApplicant));
        }
      }
      try {
        commit("appState/setSuccessMessage", {
          text: savedClientObject.successMessage,
          type: "success"
        }, {root: true});
        const newClientProfile = await patchClientProfile(savedClientObject.clientInfo, savedClientObject.clientInfo.id);
        commit("setClientProfile", {clientProfile: newClientProfile, clientData: savedClientObject});
      } catch (e) {
        commit("setClientProfile", {clientProfile: clientBackup, savedClientObject});
        commit("appState/setSuccessMessage", {
          text: "Pri ukladaní klientových dát nastala chyba",
          type: "error"
        }, {root: true});
      }
      commit("appState/setLoadingPage", false, {root: true});
      commit("setRefreshMortgageAuto", true);
      // await dispatch("fetchClient", {id: savedClientObject.clientInfo.user});
    },
    removeCoApplicant: async ({dispatch, commit}, mortgageInfo) => {
      commit("setRefreshMortgageAuto", false);
      dispatch("patchMortgage", mortgageInfo);
      commit("removeCoApplicant");
      commit("appState/setSuccessMessage", {
        text: "Spolužiadateľ bol úspešne odstránený hypotéky",
        type: "success"
      }, {root: true});
      commit("setRefreshMortgageAuto", true);
    },
    addCoApplicantToMortgage: async ({dispatch, commit}, clientId) => {
      commit("setRefreshMortgageAuto", false);
      commit("setCoApplicantLoading", true)
      const coApplicant = await getClientById(clientId, "debts,incomes,client_profile");
      await dispatch("initializeClientIncomeDti", {incomes: coApplicant?.incomes, role: "coApplicant"});
      await dispatch("initializeClientDebtDti", {debts: coApplicant?.debts, role: "coApplicant"});
      commit("setCoApplicant", coApplicant);
      commit("setCoApplicantLoading", false);
      commit("setRefreshMortgageAuto", true);
    },
  },
};
async function getMortgageApplications(applicationsIds, expand) {
  const promises = [];
  for (const id of applicationsIds) {
    promises.push({func: getApplicationById, args: id});
  }
  return await Promise.all(promises.map((promise) => promise.func(promise.args, expand)))
}
function pickHighestDti (dtiInfo) {
  if (!dtiInfo) return 0;
  let dtiMax = Number.MIN_SAFE_INTEGER;
  let dtiMaxObject = {};
  const incomeDtiData = Object.values(dtiInfo);
  for (const dtiItem of incomeDtiData) {
    if (dtiItem.success && dtiMax < dtiItem.value) {
      dtiMax = dtiItem.value;
      dtiMaxObject = dtiItem;
    }
  }
  if (dtiMax === Number.MIN_SAFE_INTEGER) dtiMax = null;
  let error = null;
  if (!dtiMax) {
    error = incomeDtiData[1].details;
    dtiMaxObject.value = 0;
  }
  return {dtiMaxObject, error};
}
//opravene cez chatgpt 12_2_2025, lebo hadzalo tento error:
// Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'map')

function getNewApplications(pOldApplications, newApplications) {
  if (!Array.isArray(pOldApplications)) {
    console.error("pOldApplications is not an array", pOldApplications);
    return { missingApplications: [], oldApplications: [] };
  }
  const oldIds = pOldApplications.map(a => a.id);
  const missingApplications = [];
  const oldApplications = [];
  for (const application of newApplications) {
    if (oldIds.includes(application.id)) {
      const indexOfOld = oldIds.indexOf(application.id);
      oldApplications.push(pOldApplications[indexOfOld]);
    } else {
      missingApplications.push(application);
    }
  }
  return {missingApplications, oldApplications};
}
function mergePinnedToOffers(pOffersArray, pinnedOffers) {
  const offersArray = (JSON.parse(JSON.stringify({offers: pOffersArray}))).offers;
  for (const offersArrayElement of offersArray) {
    offersArrayElement.pinned = false;
  }
  const mergedPinnedOffers = [];
  if (!pinnedOffers) return offersArray;
  for (const pinnedOffer of pinnedOffers) {
    const pinnedBank = pinnedOffer.bank.code;
    for (const [index, offer] of offersArray.entries()) {
      if (offer.extra.bank_code_name !== pinnedBank) {
        if (index === offersArray.length - 1) {
          pinnedOffer.pinned = true;
          mergedPinnedOffers.push(pinnedOffer);
        }
        continue;
      }
      const comparisonObjectActual = {
        celkova_suma_s_urokmi: offer.celkova_suma_s_urokmi,
        fix: offer.fix,
        mesacna_splatka: offer.mesacna_splatka,
        popl_sprac_hypo: offer.popl_sprac_hypo,
        popl_za_vedenie_uctu: offer.popl_za_vedenie_uctu,
        pozicana_suma: offer.pozicana_suma,
        sadzba: offer.sadzba,
        splatnost_r: offer.splatnost_r,
        success: offer.success,
        ucel: offer.ucel,
        value: offer.value,
      };
      const comparisonObjectPinned = {
        celkova_suma_s_urokmi: pinnedOffer.celkova_suma_s_urokmi,
        fix: pinnedOffer.fix,
        mesacna_splatka: pinnedOffer.mesacna_splatka,
        popl_sprac_hypo: pinnedOffer.popl_sprac_hypo,
        popl_za_vedenie_uctu: pinnedOffer.popl_za_vedenie_uctu,
        pozicana_suma: pinnedOffer.pozicana_suma,
        sadzba: pinnedOffer.sadzba,
        splatnost_r: pinnedOffer.splatnost_r,
        success: pinnedOffer.success,
        ucel: pinnedOffer.ucel,
        value: pinnedOffer.value,
      };
      if (deepEqual(comparisonObjectActual, comparisonObjectPinned, true)) {
        offer.pinned = true;
        break;
      }
    }
  }
  return [...mergedPinnedOffers, ...offersArray];
}

// opravene cez chatgpt 13_2_2025, lebo hadzalo tento error:
//TypeError: Cannot create property 'extra' on string ''

function mapOffersByMaturity(offersObjects, maturityObjects, banksInfo) {
  const mappedOffers = [];

  for (const offerObject of Object.values(offersObjects)) {
    if (!offerObject.offers || typeof offerObject.offers !== "object") {
      console.error("Unexpected structure in offerObject.offers:", offerObject.offers);
      continue;
    }

    if (offerObject.offers.hasOwnProperty("365")) {
      for (let [bankKey, offer] of Object.entries(offerObject.offers)) {
        if (typeof offer !== "object" || offer === null) {
          console.error("Unexpected offer type:", offer);
          continue;
        }

        const bank = banksInfo.find((bank) => bank.code === bankKey);
        if (!bank) {
          console.error("Bank not found for code:", bankKey);
          continue;
        }
        const bankId = bank.id;
        const maturity = offerObject.requested.splatnost_m / 12;

        if (!maturityObjects[maturity]?.includes(bankId)) continue;

        offer.extra = {
          mozne_zmeny_sadzieb: offer?.extra?.mozne_zmeny_sadzieb || null,
          bank_code_name: bankKey,
        };

        mappedOffers.push(offer);
      }
    } else {
      const bank = banksInfo.find((bank) => bank.id === offerObject.requested.bank);
      if (!bank) {
        console.error("Bank not found for ID:", offerObject.requested.bank);
        continue;
      }
      const bankKey = bank.code;

      if (typeof offerObject.offers !== "object" || offerObject.offers === null) {
        console.error("Unexpected offerObject.offers type:", offerObject.offers);
        continue;
      }

      offerObject.offers.extra = {
        mozne_zmeny_sadzieb: offerObject.offers?.extra?.mozne_zmeny_sadzieb || null,
        bank_code_name: bankKey,
      };

      mappedOffers.push(offerObject.offers);
    }
  }

  return mappedOffers;
}

async function getOffersByMaturity(mortgageId, maturityArrays, amountsData, fix) {
  const promises = []
  for (const [maturity, banks] of Object.entries(maturityArrays)) {
    if (banks.length === 1) {
      promises.push({func: getMortgageApplicationForSpecificBank, mortgageId, data: {fix, bank: banks[0], splatnost_m: maturity*12, ...amountsData}});
    } else {
      promises.push({func: getMortgageOffers, mortgageId, data: {fix, splatnost_m: maturity*12, ...amountsData}});
    }
  }
  return await Promise.all(promises.map((promise) => promise.func(promise.mortgageId, promise.data, "bank", true)));
}
function getMaturityArrays(offersObject, historyObject, offersData, banksInfo) {
  // console.log(offersObject, historyObject, offersData, banksInfo);
  let maturityObject = {};
  if (offersObject) {
    const alreadyPicked = [];
    for (const offer of offersObject) {
      const bankKey = offer.extra.bank_code_name;
      const bankId = banksInfo.find((bank) => bank.code === bankKey).id;
      if (alreadyPicked.includes(bankId)) {
        const indexesOfSameBank = getIndexesOfMatches(alreadyPicked, bankId);
        for (const index of indexesOfSameBank) {
          const offerAtIndex = offersObject[index];
          if (offerAtIndex.fix !== offersData.fix) continue;
          if (maturityObject.hasOwnProperty(offer.splatnost_r)) {
            maturityObject[offer.splatnost_r].push(bankId);
          } else {
            maturityObject[offer.splatnost_r] = [bankId];
          }
          for (const [maturity, banks] of Object.entries(maturityObject)) {
            if (maturity === offer.splatnost_r || banks.indexOf(bankId) === -1) continue;
            const bankIndex = banks.indexOf(bankId);
            if (bankIndex !== -1) {
              banks.splice(bankIndex, 1);
            }
          }
        }
        continue;
      }
      else alreadyPicked.push(bankId);
      if (offer.success) {
        if (maturityObject.hasOwnProperty(offer.splatnost_r)) {
          maturityObject[offer.splatnost_r].push(bankId);
        } else {
          maturityObject[offer.splatnost_r] = [bankId];
        }
      } else {
        let maturity = 40;
        if (historyObject) {
          maturity = historyObject[bankKey].splatnost
        }
        if (maturityObject.hasOwnProperty(maturity)) {
          maturityObject[maturity].push(bankId);
        } else {
          maturityObject[maturity] = [bankId];
        }
      }
    }
  } else if (historyObject) {
    for (const [bankKey, history] of Object.entries(historyObject)) {
      const bankId = banksInfo.find((bank) => bank.code === bankKey).id
      if (maturityObject.hasOwnProperty(history.splatnost)) {
        maturityObject[history.splatnost].push(bankId);
      } else {
        maturityObject[history.splatnost] = [bankId];
      }
    }
  } else {
    const banksIds = [];
    for (const bank of Object.values(banksInfo)) {
      if (!bank.id) continue;
      banksIds.push(bank.id);
    }
    maturityObject[offersData.maximalna_splatnost] = banksIds;
  }
  return maturityObject;
}
function getIndexesOfMatches(arr, searchStr) {
  const indexes = [];
  arr.forEach((item, index) => {
    if (item === searchStr) {
      indexes.push(index);
    }
  });
  return indexes;
}
// async function initMortgageOffers(mortgage, offersData, amounts) {
//   amounts.suma_nadobudnutie = Math.round(amounts.suma_nadobudnutie);
//   amounts.suma_refinancovanie = Math.round(amounts.suma_refinancovanie);
//   amounts.suma_bezucel = Math.round(amounts.suma_bezucel);
//   if (amounts.suma_nadobudnutie === 0 && amounts.suma_refinancovanie === 0 && amounts.suma_bezucel === 0) return {};
//   if (mortgage.offers_filter_history?.broker?.lastOffersSetup) {
//     const offersObject = {};
//     const offersMetaObject = {};
//     for (const [bankKey, setupItems] of Object.entries(mortgage.offers_filter_history.broker.lastOffersSetup)) {
//       if (offersMetaObject.hasOwnProperty(setupItems.splatnost)) {
//         offersMetaObject[setupItems.splatnost].push(bankKey);
//       } else {
//         offersMetaObject[setupItems.splatnost] = [];
//         offersMetaObject[setupItems.splatnost].push(bankKey);
//       }
//     }
//     for (const [maturity, banksEqual] of Object.entries(offersMetaObject)) {
//       const applicationData = {
//         splatnost_m: maturity * 12,
//         fix: offersData.fix,
//         ...amounts
//       }
//       let offersFetched = null;
//       // if (banksEqual.length === 1) {
//         offersFetched = await getMortgageOffers(mortgage.id, applicationData);
//       // } else {
//       //   offersFetched = {'banksEqual[0]': await getMortgageApplicationForSpecificBank(mortgage.id, applicationData)};
//       // }
//       for (const [fetchedBankKey, fetchedOffer] of Object.entries(offersFetched)) {
//         if (!banksEqual.includes(fetchedBankKey)) continue;
//         offersObject[fetchedBankKey] = fetchedOffer;
//       }
//     }
//     return offersObject;
//   } else {
//     const applicationData = {
//       splatnost_m: offersData.maximalna_splatnost * 12,
//       fix: offersData.fix,
//       ...amounts
//     }
//     return await getMortgageOffers(mortgage.id, applicationData);
//   }
// }

function getMaxMaturity(maturityObject, purpose) {
  let maxMaturity = 0;
  for (const maturityObjectElement of Object.values(maturityObject)) {
    const maturityObjectSpecific = maturityObjectElement[purpose];
    if (!maturityObjectSpecific.success || maturityObjectSpecific.value <= maxMaturity) continue;
    maxMaturity = maturityObjectSpecific.value;
  }
  return maxMaturity;
}

function getMortgagePurpose (amounts, purposeFirstAnalysis) {
  let maxAmount = Number.MIN_SAFE_INTEGER;
  let purpose = null;
  for (const [key, amount] of Object.entries(amounts)) {
    if (amount < maxAmount) continue;
    maxAmount = amount;
    purpose = key;
  }
  if (maxAmount === 0) {
    purpose = purposeFirstAnalysis;
  }
  switch (purpose) {
    case "suma_bezucel":
    case "americka_hypo":
      purpose = "bezucel";
      break;
    case "suma_refinancovanie":
    case "refinancovanie":
    case "konsolidacia":
      purpose = "refinancovanie";
      break;
    case "suma_nadobudnutie":
    case "nadobudnutie":
    case "uverovy_certifikat":
      purpose = "nadobudnutie";
      break;
    default:
      purpose = "nadobudnutie";
      break;
  }
  return purpose;
}

function reduceMortgage (mortgage) {
  if (!mortgage) return;
  const reducedMortgage = JSON.parse(JSON.stringify(mortgage));
  delete reducedMortgage.refinancovane_dlhy;  // delete
  delete reducedMortgage.created;  // delete
  delete reducedMortgage.modified;  // delete
  delete reducedMortgage.suma_nadobudnutie;  // delete
  delete reducedMortgage.suma_refinancovanie;  // delete
  delete reducedMortgage.suma_bezucel;  // delete
  delete reducedMortgage.applicant;  // compare
  delete reducedMortgage.coapplicant;  // compare
  delete reducedMortgage.financovane_nehnutelnosti;  // compare
  delete reducedMortgage.zakladane_nehnutelnosti;  // compare
  delete reducedMortgage.suvisiace_nehnutelnosti;  // compare
  delete reducedMortgage.uplatnene_zmeny_sadzieb;  // compare
  return reducedMortgage;
}
