<template>
  <div class="popup-form popup-cloud">
    <div
      class="content-form-popup noselect"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">
          Nahranie súboru
        </div>
      </div>
      <div>
        <p class="clientError" v-if="fileNameMissing">Zadajte prosím názov súboru</p>
        <div class="input-group">
          <label for="hodnota-nehnutelnosti" class="field-label"
          >Názov súboru<strong></strong></label
          ><input
          v-model="fileName"
          type="text"
          class="text-input w-input"
          maxlength="128"
          name="hodnota-nehnutelnosti"
          id="hodnota-nehnutelnosti"
          required
        />
        </div>
        <div
          v-for="(fileObject, index) in files"
          :key="index"
        >
          <div class="add-file-wrapper mt-2 p-relative">
            <label
              :for="'file-upload' + index"
              class="sub-ctass image-upload-btn add-file-button pointer blc-color pop-500"
            >
              {{ fileObject?.file?.name ? fileObject.file.name : "Fotka / Sken " + (index + 1) }}
              <img src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f50_icons8-done.svg" class="nav-link-icon extrasmall filter-primary MGleft10px" alt="" v-if="fileObject.file">
              <input @change="handleFiles(index)" :id="'file-upload' + index" type="file" :accept="extra ? extra : 'image/*,.pdf,.doc,.docx,.zip'"/>
            </label>
            <div
              class="closebtn-broker-app pointer"
              v-if="index > 0"
              @click="files.splice(index, 1)"
            >
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
            </div>
          </div>
          <div class="input-group" v-if="fileObject.encrypted">
            <label :for="fileObject.file.name" class="field-label">
              Zadajte heslo:
            </label>
            <div class="input-wrapper">
              <input
                type="text"
                class="text-input w-input date-input"
                v-model="fileObject.password"
                :name="fileObject.file.name"
                :id="fileObject.file.name"
              />
              <div class="input-float-button">
                <span class="pop-600 bl-color font-size-12 pointer noselect" @click="submitUnlockPdf(fileObject.password, fileObject.file, index)">
                  Odomoknúť
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-cta d-flex center MGtop20px"
          :class="{'locked-add': lockedPassword}"
          @click="addNewFile"
          v-if="!fileWord || !fileZip"
        >
          + PRIDAŤ STRANU
        </div>
      </div>
    </div>
    <div class="information-wrapper">
      <span class="blc-color">dokument môžete nahrať pomocou viacerých fotiek alebo PDF súborov, po uložení sa automaticky zlúčia do jedného</span>
      <div>
        <v-progress-circular
          v-if="loading"
          class="loading-spinner extrasmall"
          indeterminate
        ></v-progress-circular>
        <button
          v-else
          class="w-button"
          :class="{'locked-add': lockedPassword}"
          @click="transformFiles"
        >
          Uložiť a odoslať
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';
import Compressor from 'compressorjs';
import ConvertApi from "convertapi-js";
import {getFile} from "@/services/brokerService";

export default {
  name: "DocumentCloudFileUpload",
  props: {
    type: Number,
    extra: [String, null],
  },
  methods: {
    addNewFile() {
      if (this.lockedPassword) return;
      this.files.push({file: null})
    },
    checkFormLockedPdfs() {
      for (const file of this.files) {
        if (file.encrypted) return true;
      }
      return false;
    },
    async submitUnlockPdf(password, pFile, index) {
      let file = pFile;
      file = await this.unlockPdf(file, password);
      if (!file) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Zadajte správne heslo dané PDF",
          type: "error"
        });
        return;
      }
      this.$set(this.files, index, {
        encrypted: false,
        password: "",
        file
      });
      this.lockedPassword = this.checkFormLockedPdfs();
    },
    async createPdfFromFiles() {
      const pdfDoc = await PDFDocument.create();
      const sizeOverflow = this.checkFilesSizeOverflow(5000000);
      for (let file of this.files) {
        file = file.file
        if (file.type.includes("jpeg") || file.type.includes("jpg")) {
          await this.appendJpeg(file, pdfDoc, sizeOverflow);
        } else if (file.type.includes("pdf")) {
          await this.appendPdf(file, pdfDoc);
        } else if (file.type.includes("png")) {
          await this.appendPng(file, pdfDoc, sizeOverflow);
        }
      }
      const savedPdf = await pdfDoc.save();
      const bytes = new Uint8Array(savedPdf);
      return new Blob([bytes], {type: "application/pdf"});
    },
    async appendPdf(file, pdfDoc) {
      const fileBytes = await file.arrayBuffer();
      const loadedPdf = await PDFDocument.load(fileBytes);
      const pages = loadedPdf.getPageCount();
      const copiedPages = await pdfDoc.copyPages(loadedPdf, Array.from({length: pages}, (_, i) => i));
      copiedPages.forEach(page => pdfDoc.addPage(page));
    },
    async appendPng(pFile, pdfDoc, sizeOverflow) {
      let file = pFile;
      const page = pdfDoc.addPage();
      if (!sizeOverflow) {
        file = await this.compressFiles(file);
      }
      const fileBytes = await file.arrayBuffer();
      const image = await pdfDoc.embedPng(fileBytes);
      page.drawImage(image, {
        x: 0,
        y: 0,
        width: page.getWidth(),
        height: page.getHeight(),
      });
    },
    async appendJpeg(pFile, pdfDoc, sizeOverflow) {
      let file = pFile;
      const page = pdfDoc.addPage();
      if (!sizeOverflow) {
        file = await this.compressFiles(file);
      }
      const fileBytes = await file.arrayBuffer();
      const image = await pdfDoc.embedJpg(fileBytes);
      page.drawImage(image, {
        x: 0,
        y: 0,
        width: page.getWidth(),
        height: page.getHeight(),
      });
    },
    async unlockPdf(file, password="") {
      let convertApi = ConvertApi.auth('token_pbmcXbr4');
      let params = convertApi.createParams();
      params.add('File', file);
      params.add('Password', password);
      let decryptedFileInfo = null;
      try {
        decryptedFileInfo = await convertApi.convert('pdf', 'decrypt', params);
      } catch (e) {
        return null;
      }
      const urlOfFile = decryptedFileInfo.files[0].Url;
      const decryptedFile = (await getFile(urlOfFile)).data
      const loadedPdf = new File([decryptedFile], file.name, {
        type: "application/pdf",
      });
      fetch(urlOfFile, {
        method: "DELETE",
      })
      return loadedPdf;
    },
    async createFileBlob(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (event) => {
          const data = event.target.result;
          const blob = new Blob([data], { type: file.type });
          resolve(blob);
        }
        reader.onerror = reject;
      });
    },
    async transformFiles() {
      if (this.lockedPassword) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Pre uloženie súborov, prosím odomknite všetky nahraté súbory.",
          type: "error"
        });
        return;
      }
      if (!this.fileName) {
        this.fileNameMissing = true;
        return;
      }
      this.loading = true;
      this.fileNameMissing = false;
      let blob = null;
      let fileName = this.fileName;
      if (this.filePdf) {
        blob = await this.createPdfFromFiles();
        fileName += ".pdf";
      } else if (this.fileWord) {
        blob = await this.createFileBlob(this.files[0].file);
        fileName += this.files[0].file.type === "application/msword" ? ".doc" : ".docx";
      } else if (this.fileZip) {
        blob = await this.createFileBlob(this.files[0].file);
        fileName += ".zip";
      }
      this.$emit("set-meta", {blob: blob, fileName, cloudId: this.type});
      // this.download(docUrl, this.fileName);
      this.closePopup();
      this.loading = false;
    },
    checkFilesSizeOverflow(maxSize) {
      let size = maxSize
      for (const file of this.files) {
        size -= file.file.size
      }
      return size >= 0;
    },
    compressFiles(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.2,
          success: resolve,
          error: reject
        });
      });
    },
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    checkIfPdfIsEncrypted(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          const files = new Blob([reader.result], {type: 'application/pdf'});
          const pdfText = await files.text();
          const encrypted = pdfText.includes("Encrypt");
          resolve(encrypted);
        };
        reader.onerror = reject;
      })
    },
    async handleFiles(index) {
      let file = document.getElementById(`file-upload${index}`).files[0];
      if (["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"].includes(file.type)) {
        if (this.files.length > 1) {
          this.$store.commit("appState/setSuccessMessage", {
            text: "Nie je možné pridať súbor tohto typu",
            type: "error"
          });
        } else {
          this.$set(this.files, index, {file: file});
          this.fileWord = true;
        }
      } else if (["image/jpeg", "image/png", "application/pdf"].includes(file.type)) {
        this.filePdf = true;
        let pdfEncrypted = false;
        if (file.type === "application/pdf") {
          pdfEncrypted = await this.checkIfPdfIsEncrypted(file);
          let encryptedPdf = null;
          if (pdfEncrypted) {
            this.lockedPassword = true;
            this.$store.commit("appState/setSuccessMessage", {
              text: "Verifikujeme pre Vás nutnosť zadania hesla pre odomknutie súboru PDF",
              type: "success"
            })
            encryptedPdf = await this.unlockPdf(file);
            if (encryptedPdf) {
              this.lockedPassword = false;
              file = encryptedPdf;
              pdfEncrypted = false;
            }
          }
        }

        this.$set(this.files, index, {
          encrypted: pdfEncrypted,
          password: "",
          file
        });
      } else if (["application/zip", "application/x-zip-compressed"].includes(file.type)) {
        if (this.files.length > 1) {
          this.$store.commit("appState/setSuccessMessage", {
            text: "Nie je možné pridať súbor tohto typu",
            type: "error"
          });
        } else {
          this.$set(this.files, index, {file: file});
          this.fileZip = true;
        }
      } else {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Nie je možné pridať súbor tohto typu",
          type: "error"
        });
      }
      if (!this.fileName) {
        const wordOfFileName = this.files[0].file.name.split('.');
        wordOfFileName.pop();
        this.fileName = wordOfFileName.length === 1 ? wordOfFileName[0] : wordOfFileName.join(".");
      }
    }
  },
  data: () => ({
    files: [
      {
        file: null,
      }
    ],
    loading: false,
    lockedPassword: false,
    fileWord: false,
    filePdf: false,
    fileZip: false,
    fileNameMissing: false,
    fileName: null,
  }),
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  height: auto;
}

.input-float-button {
  position: absolute;
  display: block;
  top: 30px;
  right: 15px;
}

.locked-add {
  opacity: 0.2;
  cursor: not-allowed;
}

.information-wrapper {
  margin-top: 10px;
  display: flex;
  column-gap: 105px;
  flex-direction: column;
  align-items: center;
}

.information-wrapper * {
  margin-top: 10px;
}

.information-wrapper > span {
  text-align: center;
  font-size: 15px;
}
</style>