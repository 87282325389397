<template>
  <div>
    <div class="input-group">
      <label for="autocomplete" class="field-label">Adresa</label>
      <input
        @change="getSidloObject(true)"
        v-model="googlePlace.formattedAddress"
        class="text-input w-input"
        type="text"
        placeholder=""
        id="autocomplete"
      />
    </div>
    <div v-if="loaded && !shortened">
      <div class="input-group"
           v-if="addressInformationBooleans.address_text_country">
        <label for="slo-2" class="field-label"
        >Krajina<strong></strong></label
        ><input
        @change="emitAddressObject"
        v-model="addressInformation.address_text_country"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="krajina"
        id="slo-2"
        required
      />
      </div>
      <div class="input-group"
           v-if="addressInformationBooleans.address_text_county">
        <label for="Kraj" class="field-label">Kraj<strong></strong></label>
        <input
          @change="emitAddressObject"
          v-model="addressInformation.address_text_county"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="Kraj"
          placeholder=""
          id="Kraj"
          required
        />
      </div>
      <div class="input-group"
           v-if="addressInformationBooleans.address_text_district">
        <label for="okres" class="field-label">Okres<strong></strong></label>
        <input
          @change="emitAddressObject"
          v-model="addressInformation.address_text_district"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="okres"
          placeholder=""
          id="okres"
          required
        />
      </div>
      <div class="input-group"
           v-if="addressInformationBooleans.address_text_city">
        <label for="Mesto" class="field-label">Obec<strong></strong></label>
        <input
          @change="emitAddressObject"
          v-model="addressInformation.address_text_city"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="Mesto"
          id="Mesto"
          required
        />
      </div>
      <div class="input-group"
           v-if="addressInformationBooleans.address_text_street">
        <label for="Ulica" class="field-label">Ulica<strong></strong></label>
        <input
          @change="emitAddressObject"
          v-model="addressInformation.address_text_street"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="Ulica"
          id="Ulica"

        />
        <!-- :required="!notRequired?.street" -->
      </div>
      <div class="input-group">
        <label for="zip" class="field-label">Zip<strong></strong></label>
        <input
          @change="emitAddressObject"
          v-model="addressInformation.address_text_zip"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="zip"
          id="zip"
          required
        />
      </div>
      <div v-if="(house || flat) && !notBuilt">
        <div class="input-group" v-if="!notIncluded?.supisne">
          <label for="popisne" class="field-label">Súpisné číslo<strong></strong></label>
          <input
            @change="emitAddressObject"
            v-model="addressInformation.address_text_popisne"
            type="text"
            class="text-input w-input"
            maxlength="256"
            name="popisne"
            placeholder=""
            id="popisne"
          />
        </div>
        <div class="input-group">
          <label for="orient" class="field-label">{{ flat ? "Vchod" : "Orientačné číslo" }}<strong></strong></label>
          <input
            @change="emitAddressObject"
            v-model="addressInformation.address_text_number"
            type="text"
            class="text-input w-input"
            maxlength="256"
            name="orient"
            placeholder=""
            id="orient"
          />
        </div>
      </div>
      <div v-else-if="land">
        <div class="input-group">
          <label for="listVlast" class="field-label">Číslo listu vlastníctva<strong></strong></label>
          <input
            @change="emitAddressObject"
            v-model="addressInformation.cislo_lv"
            type="text"
            class="text-input w-input"
            maxlength="256"
            name="listVlast"
            placeholder=""
            id="listVlast"
          />
        </div>
        <div class="input-group">
          <label for="cisParcely" class="field-label">Číslo parcely<strong></strong></label>
          <input
            @change="emitAddressObject"
            v-model="addressInformation.cislo_parcely"
            type="text"
            class="text-input w-input"
            maxlength="256"
            name="cisParcely"
            placeholder=""
            id="cisParcely"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "SidloInput",
  props: {
    notRequired: [Object, null],
    notIncluded: [Object, null],
    label: String,
    type: String,
    shortened: Boolean,
    notBuilt: Boolean,
    googlePlaceId: [String, null],
    propertyMeta: [Object, null],
  },
  watch: {
    type (newType) {
      this.flat = newType === "flat";
      this.house = newType === "house";
      this.land = newType === "land";
    },
    notBuilt (built) {
      if (!built) return;
      this.addressInformation.address_text_number = null;
      this.addressInformation.address_text_popisne = null;
    },
  },
  async mounted () {

    this.flat = this.type === "flat";
    this.house = this.type === "house";
    this.land = this.type === "land";

    const loader = new Loader({
      version: "weekly",
      apiKey: "AIzaSyACFOa66Be06e4COYwMt_UcdWPbAAcFe9o",
      id: "__googleMapsScriptId",
      libraries: ["places"],
      url: "https://maps.googleapis.com/maps/api/js",
    });

    const google = await loader.load();

    const element = document.getElementById("autocomplete");

    const autocomplete = new google.maps.places.Autocomplete(element);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.addressInformation.google_place_id = place["place_id"];
      this.googlePlace.components = place["address_components"];
      this.googlePlace.formattedAddress = place["formatted_address"];
    });

    if (this.firstTime && !this.googlePlaceId) return;
    const geocoder = new google.maps.Geocoder();
    const googlePlaceObject = (await geocoder.geocode({placeId: this.googlePlaceId}))?.results[0];
    if (!googlePlaceObject) {
      this.firstTime = false;
      return
    }
    this.googlePlace.formattedAddress = googlePlaceObject.formatted_address;
    this.googlePlace.components = googlePlaceObject.address_components;
    if (this.propertyMeta.cislo_lv) {
      this.addressInformation.cislo_lv = this.propertyMeta.cislo_lv;
    }
    if (this.propertyMeta.cislo_parcely) {
      this.addressInformation.cislo_parcely = this.propertyMeta.cislo_parcely;
    }
    if (this.propertyMeta.address_text_number) {
      this.addressInformation.address_text_number = this.propertyMeta.address_text_number;
    }
    if (this.propertyMeta.address_text_popisne) {
      this.addressInformation.address_text_popisne = this.propertyMeta.address_text_popisne;
    }
    if (this.propertyMeta.address_text_county) {
      this.addressInformation.address_text_county = this.propertyMeta.address_text_county;
      this.addressInformationBooleans.address_text_county = false;
    }
    if (this.propertyMeta.address_text_district) {
      this.addressInformation.address_text_district = this.propertyMeta.address_text_district;
      this.addressInformationBooleans.address_text_district = false;
    }
    if (this.propertyMeta.address_text_city) {
      this.addressInformation.address_text_city = this.propertyMeta.address_text_city;
      this.addressInformationBooleans.address_text_city = false;
    }
    if (this.propertyMeta.address_text_street) {
      this.addressInformation.address_text_street = this.propertyMeta.address_text_street;
      this.addressInformationBooleans.address_text_street = false;
    }
    if (this.propertyMeta.address_text_zip) {
      this.addressInformation.address_text_zip = this.propertyMeta.address_text_zip;
    }
    this.addressInformation.google_place_id = this.googlePlaceId;
    this.getSidloObject(false);
    this.firstTime = false;
    this.loaded = true;
  },
  methods: {
    emitAddressObject () {
      this.$emit("address-change", this.addressInformation);
    },
    refreshAddress () {
      this.loaded = false;
      this.googlePlace = {
        components: null,
        formattedAddress: this.googlePlace.formattedAddress,
      };
      this.addressInformation = {
        google_place_id: null,
        address_text_country: null,
        address_text_county: null,
        address_text_district: null,
        address_text_city: null,
        address_text_street: null,
        address_text_number: null,
        address_text_popisne: null,
        address_text_zip: null,
        cislo_lv: null,
        cislo_parcely: null
      }
    },
    setGooglePlaceFormattedAddress () {
      this.googlePlace.formattedAddress = [
        this.form.address_text_street,
        this.form.address_text_city,
        this.form.address_text_country,
      ]
        .filter(Boolean)
        .join(", ");
    },
    addressPromise () {
      return new Promise((resolve) => {
        let counter = 0;
        const interval = setInterval(() => {
          counter = counter + 1;
          if (this.googlePlace.components) {
            clearInterval(interval);
            this.loaded = true;
            resolve(this.googlePlace.components);
          }
          if (counter > 100) {
            clearInterval(interval);
            this.loaded = false;
            resolve([]);
          }
        }, 100);
      });
    },
    async getSidloObject (force) {
      if (this.shortened) {
        this.emitAddressObject();
        return;
      }
      let googlePlaceComponents = [];
      if (force) {
        this.refreshAddress();
        googlePlaceComponents = await this.addressPromise();
      } else {
        googlePlaceComponents = this.googlePlace.components;
      }
      for (const googlePlaceComponent of googlePlaceComponents) {
        if (googlePlaceComponent.types.includes("country") &&
          ["SK", "AT", "CZ"].includes(googlePlaceComponent.short_name)) {
          this.addressInformationBooleans.address_text_country = false;
          this.addressInformation.address_text_country = googlePlaceComponent.short_name;
        } else if (googlePlaceComponent.types.includes("administrative_area_level_1")) {
          this.addressInformationBooleans.address_text_county = false;
          this.addressInformation.address_text_county = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("administrative_area_level_2")) {
          this.addressInformationBooleans.address_text_district = false;
          this.addressInformation.address_text_district = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("sublocality_level_1")) {
          this.addressInformationBooleans.address_text_city = false;
          this.addressInformation.address_text_city = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("route")) {
          this.addressInformationBooleans.address_text_street = false;
          this.addressInformation.address_text_street = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("street_number")) {
          this.addressInformation.address_text_number = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("premise")) {
          this.addressInformationBooleans.address_text_popisne = false;
          this.addressInformation.address_text_popisne = googlePlaceComponent.long_name;
        } else if (googlePlaceComponent.types.includes("postal_code")) {
          this.addressInformation.address_text_zip = googlePlaceComponent.long_name;
        }
      }
      this.emitAddressObject();
    },
  },
  data: () => ({
    firstTime: true,
    house: false,
    flat: false,
    land: false,
    loaded: false,
    googlePlace: {
      components: null,
      formattedAddress: null,
    },
    addressInformation: {
      google_place_id: null,
      address_text_country: null,
      address_text_county: null,
      address_text_district: null,
      address_text_city: null,
      address_text_street: null,
      address_text_number: null,
      address_text_popisne: null,
      address_text_zip: null,
      cislo_lv: null,
      cislo_parcely: null
    },
    addressInformationBooleans: {
      address_text_country: true,
      address_text_county: true,
      address_text_district: true,
      address_text_city: true,
      address_text_street: true,
    }
  }),
}
</script>

<style scoped>

</style>
