<template>
  <div>
    <div class="amounts-title text-small light blc-color">
      <p>Sumy</p>
      <p>Diety</p>
    </div>
    <div class="section-title">
      <div v-if="canAddNextMonth" class="text-primary" @click="addNextMonth">
        + pridať nasledujúci mesiac
      </div>
    </div>
    <div class="date-picker-wrapper">
      <div class="diets-amounts-month-wrapper" v-for="(month, index) in monthlyData" :key="index">
        <div class="input-group">
          <label :for="'Month-payment' + index" class="field-label">
            {{ months[month.mesiac] }} {{ month.rok }}
          </label>
          <div class="month-wrapper">
            <input
              @change="showEstimatedDtiToUser"
              v-model.number="month.suma"
              type="number"
              step="0.01"
              min="0"
              class="text-input w-input"
              :name="'Month-payment' + index"
              :id="'Month-payment' + index"
              data-name="'Month-payment' + index"
              required
            />
            <div
              v-if="index !== 0"
              class="copy-button"
              @click="copy(index - 1, index, 'suma')"
            >
            <span class="pop-600 bl-color copy-text pointer noselect">
              COPY
            </span>
            </div>
          </div>
          <div
            class="closebtn-broker-app pointer"
            @click="removeMonthlyIncome(index)"
            v-if="index > 2"
          >
            <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
          </div>
        </div>
        <div class="input-group">
          <label :for="'Month-payment' + index" class="field-label">
            {{ months[month.mesiac] }} {{ month.rok }}
          </label>
          <div class="month-wrapper">
            <input
              @change="showEstimatedDtiToUser"
              v-model.number="month.diety"
              type="number"
              step="0.01"
              min="0"
              class="text-input w-input"
              :name="'Month-payment' + index"
              :id="'Month-payment' + index"
              data-name="'Month-payment' + index"
              required
            />
            <div
              v-if="index !== 0"
              class="copy-button"
              @click="copy(index - 1, index, 'diety')"
            >
            <span class="pop-600 bl-color copy-text pointer noselect">
              COPY
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-title">
      <div class="text-primary" @click="addPreviousMonth">
        + predchádzajúci mesiac
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthlyAmountInput",
  props: {
    initMonths: Array,
    averages: [Object, null],
  },
  created() {
    this.monthlyData = this.initMonths;
  },
  computed: {
    canAddNextMonth() {
      if (this.monthlyData.length === 0) return true;
      const firstEntry = this.monthlyData[0];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      return firstEntry.rok < currentYear || (firstEntry.rok === currentYear && firstEntry.mesiac < currentMonth);
    }
  },
  methods: {
    addPreviousMonth() {
      const lastEntry = this.monthlyData[this.monthlyData.length - 1];
      let newMonth = lastEntry.mesiac - 1;
      let newYear = lastEntry.rok;
      if (newMonth < 1) {
        newMonth = 12;
        newYear--;
      }
      if (!this.monthlyData.some(m => m.mesiac === newMonth && m.rok === newYear)) {
        this.monthlyData.push({
          income: lastEntry.income,
          mesiac: newMonth,
          rok: newYear,
          diety: 0,
          suma: null,
        });
      }
    },
    addNextMonth() {
      const firstEntry = this.monthlyData[0];
      let newMonth = firstEntry.mesiac + 1;
      let newYear = firstEntry.rok;
      if (newMonth > 12) {
        newMonth = 1;
        newYear++;
      }
      if (!this.monthlyData.some(m => m.mesiac === newMonth && m.rok === newYear)) {
        this.monthlyData.unshift({
          income: firstEntry.income,
          mesiac: newMonth,
          rok: newYear,
          diety: 0,
          suma: null,
        });
      }
    },
    showEstimatedDtiToUser() {
      this.$emit("refresh-dti");
    },
    copy(from, to, which) {
      this.monthlyData[to][which] = this.monthlyData[from][which];
      this.showEstimatedDtiToUser();
    },
    removeMonthlyIncome(index) {
      this.monthlyData.splice(index, 1);
      this.showEstimatedDtiToUser();
    },
  },
  data: () => ({
    monthlyData: [],
    months: {
      1: "Január", 2: "Február", 3: "Marec", 4: "Apríl", 5: "Máj", 6: "Jún",
      7: "Júl", 8: "August", 9: "September", 10: "Október", 11: "November", 12: "December"
    },
  }),
};
</script>

<style lang="scss" scoped>
.amounts-title {
  display: flex;
  justify-content: space-around;
}

.diets-amounts-month-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.copy-text {
  font-size: 12px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.closebtn-broker-app {
  top: 5px;
}

.date-picker-wrapper {
  margin-top: 6px;
  display: grid;
  grid-column-gap: 10px;
}

.icon-add-date {
  top: 28px;
  right: 15px;
  position: absolute;
  width: 27px;
  opacity: 0.6;
}

.copy-button {
  position: absolute;
  display: none;
  top: 30px;
  right: 15px;
}

.month-wrapper {
  width: 100%;
  height: auto;
}

.month-wrapper:hover {
  & .copy-button {
    display: block;
  }
}
</style>