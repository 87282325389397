<template>
  <div>
    <div class="pdfPopupNavbar">
      <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
           @click="hideClientNeedsGenerator"/>
      <div
        v-if="!generatingPdf"
        class="w-button pointer"
        @click="generate"
      >
        GENEROVAŤ
      </div>
      <v-progress-circular
        v-else
        size="25"
        width="3"
        indeterminate
        :color="'#8c2a5e'"
      ></v-progress-circular>
    </div>
    <div class="zopkbody" id="generatedNeedsPart" v-if="!loading">
      <div class="page-wrapper">
        <div class="container-zok w-container">
          <div class="header2">
            <div class="logo"></div>
            <div class="subeheading limitwidth">ZÁZNAM O POŽIADAVKÁCH A POTREBÁCH KLIENTA A O POSKYTNUTÍ INFORMÁCIÍ POTENCIÁLNEMU KLIENTOVI<br>pre
              sektor Poskytovanie úverov, úverov na bývanie a spotrebiteľských úverov, <span class="ucel">{{ purposeLabel }} </span>
              <span class="bold">, klient p. {{ $store.getters["mortgageDetailState/getApplicant"].last_name }}</span></div>
          </div>
          <p class="paragraph">
            záznam o požiadavkách a potrebách klienta a o poskytnutí informácií potencionálnemu klientovi podľa § 32
            ods.
            2 až 4, §
            33 a § 35 Zákona č. 186/2009 Z. z. o finančnom sprostredkovaní a finančnom poradenstve a o zmene a doplnení
            niektorých zákonov,
            v platnom znení ( ďalej len „zákon o finančnom sprostredkovaní”), o požiadavkách, potrebách, skúsenostiach,
            znalostiach a
            finančnej situácii neprofesionálneho klienta v súvislosti s finančnou službou, ktorá je predmetom finančného
            sprostredkovania
            a o poskytnutí informácií potenciálnemu klientovi a klientovi pred uzatvorením zmluvy o poskytnutí finančnej
            služby (ďalej len „záznam”).
          </p>
          <div class="section-divider">
            <div>1. Vysvetlenie pojmov</div>
          </div>
          <div class="subheading">
            <strong>Finančné sprostredkovanie</strong>
          </div>
          <p class="paragraph">
            slúži k ľahšiemu zorientovaniu sa v množstve ponúkaných produktov a služieb na finančnom trhu; jeho
            výsledkom
            je predloženie
            ponúk Klientovi Finančným agentom a následné uzatvorenie zmluvy s Finančnou inštitúciou, tzn. Finančný agent
            Vám odporučí a dá Vám na výber produkt,
            či službu podľa Vašich predstáv, potrieb a možností, zásadný rozdiel oproti finančnému poradenstvu spočíva v
            tom že služby Finančného agenta sú hradené
            finančnou inštitúciou; táto činnosť má právny základ v zákone č. 186/2009 Z. z. o finančnom sprostredkovaní
            a
            finančnom poradenstve a o zmene a
            doplnení niektorých zákonov, v platnom znení
          </p>
          <div class="subheading">
            <strong>Finančná inštitúcia</strong>
          </div>
          <p class="paragraph">sú na účely tohto záznamu finančné inštitúcie, najmä tuzemské banky a pobočky
            zahraničných
            bánk</p>
          <div class="subheading">
            <strong>Finančná služba</strong>
          </div>
          <p class="paragraph">
            je akákoľvek služba finančnej povahy, ktorú ponúka poskytovateľ finančnej služby, v tomto prípade Finančná
            inštitúcia, pričom na
            účely záznamu ide o tieto Finančné služby - pôžičky všetkých druhov vrátane spotrebných úverov,
            hypotekárnych
            úverov
          </p>
          <div class="subheading">
            <strong>Aplikácia bankari.sk</strong>
          </div>
          <p class="paragraph">
            PFA ako užívateľ aplikácie bankari.sk zároveň vykonáva finančné sprostredkovanie aj prostredníctvom /
            pomocou portálu bankari.sk
            respektíve jeho subdomény app.bankari.sk. Aplikácia bankari.sk pred uzatvorením zmluvy o poskytnutí
            Finančnej
            služby zaznamenáva požiadavky
            a potreby klienta, súvisiace s dojednávanou Finančnou službou a dôvody, na základe ktorých PFA poskytuje
            svoje
            odporúčanie pre výber
            tej ktorej Finančnej služby, a to v písomnej podobe alebo v elektronickej podobe na internetovej stránke
            www.bankari.sk /
            <a href="http://www.app.bankari.sk">www.app.bankari.sk</a> prostredníctvom na to určených častí formulára a
            kalkulačiek, formulárov zmlúv,
            alebo prostredníctvom e-mailu a to v zmysle súhlasu s elektronickou komunikáciou udelenou Klientom
            <strong> {{ getConvertedDate(applicant.clientObject.client_profile.created) }} </strong>PFA
          </p>
          <!-- <div class="pagebreak"></div> -->
          <div class="section-divider">
            <div>
              2. Identifikácia Finančného agenta a Klienta
            </div>
          </div>
          <div class="subheading">
            <strong>Finančný agent</strong>
          </div>
          <p class="paragraph">
            Finančným agentom vykonávajúcim finančné sprostredkovanie je spoločnosť {{ staffProfil.staff_profile.sfa.obchodne_meno }}. so sídlom {{ staffProfil.staff_profile.sfa.adresa }}, IČO: {{ staffProfil.staff_profile.sfa.ico }}
            ako samostatný finančný agent, zapísaný v registri NBS pod registračným číslom <strong>{{ staffProfil.staff_profile.sfa.reg_cislo}}</strong> v podregistroch
            poistenia a zaistenia, kapitálového trhu, prijímania vkladov, poskytovania úverov, úverov na bývanie a
            spotrebiteľských úverov, doplnkového dôchodkového sporenia a starobného dôchodkového sporenia (<em>ďalej aj
            ako „Samostatný finančný agent“ alebo „SFA“</em>), alebo podriadený finančný agent vykonávajúceho finančné sprostredkovanie na základe
            písomnej
            zmluvy so spoločnosťou {{ staffProfil.staff_profile.sfa.obchodne_meno }}, a to {{ staffProfil.staff_profile.obchodne_meno }} so sídlom {{ staffProfil.staff_profile.adresa }}, IČO: {{ staffProfil.staff_profile.ico }}, zapísaný v registri NBS v zozname podriadených finančných
            agentov
            v sektore poskytovania úverov, úverov na bývanie spotrebiteľských úverov s registračným číslom
            <strong>{{ staffProfil.staff_profile.cislo_registracie_NBS }} </strong>(<em>ďalej
            aj ako „Podriadený Finančný agent“ alebo PFA</em>), KONTAKT ({{ staffProfil.staff_profile.email }}, {{ staffProfil.staff_profile.telephone }}).
            Podľa
            zoznamu finančných agentov vedeného NBS (zápis PFA / SFA / v registri NBS je možné overiť na internetovej
            adrese: <a href="http://regfap.nbs.sk/search.php">http://regfap.nbs.sk/search.php</a>)</p>
          <div class="subheading"><strong>Klient</strong></div>
          <p class="paragraph">
            Fyzická osoba:
            <span id="ziadatel_data" class="dynamic-data-block">
              {{ applicant.clientObject.degree | addComma }} {{ applicant.clientObject.first_name }}
              {{
                applicant.clientObject.last_name | addComma
              }} adresa: 
              {{ applicant.clientObject.client_profile.permanent_address_street }}
              {{ applicant.clientObject.client_profile.permanent_address_number | addComma }}
              {{ applicant.clientObject.client_profile.permanent_address_zip  }}
              {{ applicant.clientObject.client_profile.permanent_address_city | addComma}}
              príslušnosť:
              {{ applicant.clientObject.client_profile.statna_prislusnost | addComma }}
              rodné číslo:
              {{ applicant.clientObject.client_profile.rodne_cislo | addComma }}
              číslo dokladu totožnosti:
              {{ applicant.clientObject.client_profile.op_cislo | addComma }}
              rodinný stav:
              {{
                translateFromDbValue('clientProfileForm', 'rodinny_stav', applicant.clientObject.client_profile.rodinny_stav) | addComma
              }}
              kontakt: (+421)
              {{ applicant.clientObject.client_profile.telephone | addComma }} {{ applicant.clientObject.email }}
            </span>
            pričom PFA vyhlasuje, že overil totožnosť a identifikoval klienta v rozsahu potrebnom na uzatvorenie zmluvy
            o
            poskytovaní finančnej služby v zmysle zákona o finančnom sprostredkovaní a č. 186/2009 Z.z. a zákona č.
            297/2008 Z.z. o ochrane pred legalizáciou príjmov z trestnej činnosti a o ochrane pred financovaním
            terorizmu
            a o zmene a doplnení niektorých zákonov. Klient vyhlasuje že nemá pracovné a ani iné profesionálne
            skúsenosti
            s finančným trhom, pre vylúčenie pochybností to znamená, že požaduje aby sa s ním zaobchádzalo ako s
            neprofesionálnym klientom.
            <br>
          </p>
          <div v-if="coApplicant.clientObject">
            <div class="subheading"><strong>Spoluklient</strong></div>
            <p class="paragraph">
              Fyzická osoba:
              <span id="ziadatel_data" class="dynamic-data-block">
              {{ coApplicant.clientObject.degree | addComma }} {{ coApplicant.clientObject.first_name }}
              {{
                  coApplicant.clientObject.last_name | addComma
                }} 
              adresa:
              {{ coApplicant.clientObject.client_profile.permanent_address_street }}
              {{ coApplicant.clientObject.client_profile.permanent_address_number | addComma }}
              {{ coApplicant.clientObject.client_profile.permanent_address_zip | addComma }}
              {{ coApplicant.clientObject.client_profile.permanent_address_city | addComma}}
              príslušnosť:
              {{ coApplicant.clientObject.client_profile.statna_prislusnost | addComma }}
              rodné číslo:
              {{ coApplicant.clientObject.client_profile.rodne_cislo | addComma }}
              číslo dokladu totožnosti:
              {{ coApplicant.clientObject.client_profile.op_cislo | addComma }}
              rodinný stav:
              {{
                  translateFromDbValue('clientProfileForm', 'rodinny_stav', coApplicant.clientObject.client_profile.rodinny_stav) | addComma
                }}
              kontakt: (+421)
              {{ coApplicant.clientObject.client_profile.telephone | addComma }} {{ coApplicant.clientObject.email }}
            </span>
              a ktorý zároveň zdieľa s Klientom domácnosť alebo je rodinným príslušníkom. Klient a Spoluklient ďalej
              spoločne aj ako "Klient". <br>
            </p>
          </div>
          <p class="paragraph">Klient zároveň svojím podpisom na tomto zázname vyhlasuje a potvrdzuje že:<br>‍<br>- koná
            vo vlastnom mene,<br>- nie je politický exponovaná osoba,<br>- má záujem o uzatvorenie zmluvy o
            Finančnej službe,<br>- vykonáva tento obchod na vlastný účet,<br>- finančné prostriedky použité pri tomto
            obchode sú vlastníctvom Klienta,<br>- finančné prostriedky použité pri tomto obchode pochádzajú z vlastných
            zdrojov Klienta,<br>- je konečným užívateľom výhod Finančnej služby,<br>- nie je daňový rezident USA.
          </p>
          <div class="section-divider">
            <div>3. Potreby a požiadavky Klienta</div>
          </div>
          <p class="paragraph">
            Klient požiadal PFA o sprostredkovanie Finančnej služby za účelom
            financovania nasledovných potrieb klienta:
          </p>
          <div class="w-layout-grid-c grid-ii-columns" v-if="pickClientOrBrokerAmount('suma_nadobudnutie') !== 0">
            <div class="legend-block">
              <div>Účel hypotéky</div>
            </div>
            <div class="legend-block">
              <div>hodnota založených nehn.</div>
            </div>
            <!-- <div class="legend-block">
              <div>LTV</div>
            </div> -->
            <!-- <div class="legend-block">
              <div>kúpna cena nehnuteľnosti
              </div>
            </div> -->
            <div class="content-block">
              <div>
                Nadobudnutie
                {{ sumAmounts(pickClientOrBrokerAmount('all')) }} EUR
              </div>
            </div>
            <div class="content-block">
              <div>{{ sumPropertiesValues('foundedProperties') }} EUR</div>
            </div>
            <!-- <div class="content-block">
              <div>{{ mortgage.ltv.value }}%</div>
            </div> -->
            <!-- <div class="content-block">
              <div>{{ clientAmounts.suma_nadobudnutie }} EUR</div>
            </div> -->
          </div>
          <div class="w-layout-grid-c grid-iv-columns" v-if="pickClientOrBrokerAmount('suma_refinancovanie') !== 0">
            <div class="legend-block">
              <div>Účel hypotéky</div>
            </div>
            <div class="legend-block">
              <div>hodnota založených nehn.</div>
            </div>
            <div class="legend-block">
              <div>LTV</div>
            </div>
            <div class="legend-block">
              <div>zostatok refinancovaných<br>a
                konsolidovaných
                úverov
              </div>
            </div>
            <div class="content-block">
              <div>
                Refinancovanie +
                {{ sumAmounts(pickClientOrBrokerAmount('all')) }} EUR
              </div>
            </div>
            <div class="content-block">
              <div>{{ sumPropertiesValues('foundedProperties') }} EUR</div>
            </div>
            <div class="content-block">
              <div>{{ mortgage.ltv.value * 100 }}%</div>
            </div>
            <div class="content-block">
              <div>{{ pickClientOrBrokerAmount('suma_refinancovanie') }} EUR</div>
            </div>
          </div>
          <div class="w-layout-grid-c grid-iv-columns" v-if="pickClientOrBrokerAmount('suma_bezucel') !== 0">
            <div class="legend-block">
              <div>Účel hypotéky</div>
            </div>
            <div class="legend-block">
              <div>hodnota založených nehn.</div>
            </div>
            <div class="legend-block">
              <div>LTV</div>
            </div>
            <div class="legend-block">
              <div>požadovaná suma bezúčelovej hypotéky</div>
            </div>
            <div class="content-block">
              <div>
                Bezúčelový úver +
                {{ sumAmounts(pickClientOrBrokerAmount('all')) }} EUR
              </div>
            </div>
            <div class="content-block">
              <div>{{ sumPropertiesValues('foundedProperties') }} EUR</div>
            </div>
            <div class="content-block">
              <div>{{ mortgage.ltv.value * 100 }}%</div>
            </div>
            <div class="content-block">
              <div>{{ pickClientOrBrokerAmount('suma_bezucel') }} EUR</div>
            </div>
          </div>
          <p class="paragraph marginTop">Požiadavky na parametre Finančnej služby na naplnenie hore uvedenej
            potreby sú:
          </p>
          <div class="w-layout-grid-c grid-iv-columns">
            <div class="legend-block">
              <div>Splatnosť</div>
            </div>
            <div class="legend-block">
              <div>Fixácia</div>
            </div>
            <div class="legend-block">
              <div>Max. výška splátky</div>
            </div>
            <div class="legend-block">
              <div>Preferovaná banka</div>
            </div>
            <div class="content-block">
              <div>{{ getMaxFromApplications('splatnost') }} rokov</div>
            </div>
            <div class="content-block">
              <div>{{ getMaxFromApplications('fix') }} rokov</div>
            </div>
            <div class="content-block">
              <div>{{ getMaxFromApplications('splatka') * 1.1 | ceil }} EUR</div>
            </div>
            <div class="content-block">
              <div v-for="(bank, index) in prefferedBanks" :key="index">
                {{ bank }},
              </div>
            </div>
          </div>
          <!-- <div class="pagebreak"></div> -->
          <div class="section-divider">
            <div>4. Identifikácia situácie Klienta (príjmy)</div>
          </div>
          <div class="w-layout-grid-c grid-iv-columns">
            <div class="legend-block">
              <div>Príjmy</div>
            </div>
            <div class="legend-block">
              <div>profesia/odvetvie</div>
            </div>
            <div class="legend-block">
              <div>čistý mesačný príjem</div>
            </div>
            <div class="legend-block">
              <div>dopad na DSTI</div>
            </div>
          </div>
          <div
            class="w-layout-grid-c grid-iv-columns"
            v-for="(income, index) in filterActive(applicant.incomes)"
            :key="index + 'appinc'"
          >
            <div class="content-block">
              <div>{{ index + 1 }}. {{ translateFromDbValue('incomeForm', 'druh', income.druh) }}
              </div>
            </div>
            <div class="content-block">
              <div v-if="['prijem_zivnostnika', 'prijem_z_sro'].includes(income.druh)"> {{ income.zamestnavatel_odvetvie }}</div>
              <div v-else> {{ $store.getters['formStore/getProfessionLabelById'](income.profesia) }}</div>
            </div>
            <div class="content-block">
              <div> {{ income.dti.extra?.prijem_mo | ceil }} EUR</div>
            </div>
            <div class="content-block">
              <div>+ {{ getDstiCalculation(filterActive(applicant.incomes), index) | ceil }} EUR</div>
            </div>
          </div>
          <p
            class="paragraph marginTop"
            v-for="(income, index) in filterActive(applicant.incomes)"
            :key="index + 'appincinf'"
          >
            {{ getInformationAboutIncome(income, index)}}
          </p>
          <div v-if="coApplicant.clientObject">
            <div class="section-divider">
              <div>Identifikácia situácie Spoluklienta (príjmy)</div>
            </div>
            <div class="w-layout-grid-c grid-iv-columns">
              <div class="legend-block">
                <div>Príjmy</div>
              </div>
              <div class="legend-block">
                <div>profesia/odvetvie</div>
              </div>
              <div class="legend-block">
                <div>čistý mesačný príjem</div>
              </div>
              <div class="legend-block">
                <div>dopad na DSTI</div>
              </div>
            </div>
            <div
              class="w-layout-grid-c grid-iv-columns"
              v-for="(income, index) in filterActive(coApplicant.incomes)"
              :key="index + 'coappinc'"
            >
              <div class="content-block">
                <div>{{ index + 1 }}. {{ translateFromDbValue('incomeForm', 'druh', income.druh) }}</div>
              </div>
              <div class="content-block">
                <div v-if="['prijem_zivnostnika', 'prijem_z_sro'].includes(income.druh)"> {{ income.zamestnavatel_odvetvie }}</div>
                <div v-else> {{ $store.getters['formStore/getProfessionLabelById'](income.profesia) }}</div>
              </div>
              <div class="content-block">
                <div> {{ income.dti.extra?.prijem_mo | ceil }} EUR</div>
              </div>
              <div class="content-block">
                <div>+ {{ getDstiCalculation(filterActive(coApplicant.incomes), index) | ceil }} EUR</div>
              </div>
            </div>
            <p
              class="paragraph marginTop"
              v-for="(income, index) in filterActive(coApplicant.incomes)"
              :key="index + 'coappincinf'"
            >
              {{ getInformationAboutIncome(income, index)}}
            </p>
          </div>
          <div class="section-divider">
            <div>5. Identifikácia situácie Klienta (existujúce úvery a výdavky)</div>
          </div>
          <div class="w-layout-grid-c grid-iii-columns" v-if="doesClientHaveDebts(applicant)">
            <div class="legend-block">
              <div>Výdavky Klienta</div>
            </div>
            <div class="legend-block">
              <div>splátka</div>
            </div>
            <div class="legend-block">
              <div>zostatok</div>
            </div>
          </div>
          <p class="paragraph" v-else>
            Klient nemá žiadne výdavky úverového typu od bankovej či nebankovej inštitúcie.
          </p>
          <div
            class="w-layout-grid-c grid-iii-columns"
            v-for="(debt, index) in filterVisible(applicant.debts)"
            :key="index + 'appdeb'"
          >
            <div class="content-block">
              <div>{{ index + 1 }}. {{ translateFromDbValue('debtForm', 'typ', debt.typ) }} -
                {{ translateBank(debt.banka) }}
              </div>
            </div>
            <div class="content-block">
              <div> {{ debt.vyska_splatky | ceil }} EUR</div>
            </div>
            <div class="content-block">
              <div> {{ debt.zostatok ? debt.zostatok + " EUR" : "" }}</div>
            </div>
          </div>
          <div v-if="coApplicant.clientObject">
            <div class="section-divider">
              <div>Identifikácia situácie Spoluklienta (existujúce úvery a výdavky)</div>
            </div>
            <div class="w-layout-grid-c grid-iii-columns" v-if="doesClientHaveDebts(coApplicant)">
              <div class="legend-block">
                <div>Výdavky Klienta</div>
              </div>
              <div class="legend-block">
                <div>splátka</div>
              </div>
              <div class="legend-block">
                <div>zostatok</div>
              </div>
            </div>
            <p class="paragraph" v-else>
              Klient nemá žiadne výdavky úverového typu od bankovej či nebankovej inštitúcie.
            </p>
            <div
              class="w-layout-grid-c grid-iii-columns"
              v-for="(debt, index) in filterVisible(coApplicant.debts)"
              :key="index + 'coappdeb'"
            >
              <div class="content-block">
                <div>{{ index + 1 }}. {{ translateFromDbValue('debtForm', 'typ', debt.typ) }} -
                  {{ translateBank(debt.banka) }}
                </div>
              </div>
              <div class="content-block">
                <div> {{ debt.vyska_splatky * 1.1 | ceil }} EUR</div>
              </div>
              <div class="content-block">
                <div> {{ debt.zostatok ? debt.zostatok : "" }} EUR</div>
              </div>
            </div>
          </div>
          <div class="section-divider">
            <div>6. Identifikácia situácie Klienta (rodinný stav)</div>
          </div>
          <div class="w-layout-grid-c grid-iv-columns">
            <div class="legend-block">
              <div>rodinný stav Klienta</div>
            </div>
            <div class="legend-block">
              <div>počet detí</div>
            </div>
            <div class="legend-block">
              <div>najvyš. vzdelanie</div>
            </div>
            <div class="legend-block">
              <div>vek</div>
            </div>
            <div class="content-block">
              <div>
                {{
                  translateFromDbValue('clientProfileForm', 'rodinny_stav', applicant.clientObject.client_profile.rodinny_stav)
                }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{ applicant.clientObject.client_profile.pocet_nezaopatrenych_deti }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{
                  translateFromDbValue('clientProfileForm', 'vzdelanie', applicant.clientObject.client_profile.vzdelanie)
                }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{ getAge(applicant.clientObject.client_profile.datum_narodenia) }}
              </div>
            </div>
          </div>
          <div class="w-layout-grid-c grid-iv-columns" style="margin-top: 20px" v-if="coApplicant.clientObject">
            <div class="legend-block">
              <div>rodinný stav Spoluklienta</div>
            </div>
            <div class="legend-block">
              <div>počet detí</div>
            </div>
            <div class="legend-block">
              <div>najvyš. vzdelanie</div>
            </div>
            <div class="legend-block">
              <div>vek</div>
            </div>
            <div class="content-block">
              <div>
                {{
                  translateFromDbValue('clientProfileForm', 'rodinny_stav', coApplicant.clientObject.client_profile.rodinny_stav)
                }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{ coApplicant.clientObject.client_profile.pocet_nezaopatrenych_deti }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{
                  translateFromDbValue('clientProfileForm', 'vzdelanie', coApplicant.clientObject.client_profile.vzdelanie)
                }}
              </div>
            </div>
            <div class="content-block">
              <div>
                {{ getAge(coApplicant.clientObject.client_profile.datum_narodenia) }}
              </div>
            </div>
          </div>
          <p class="paragraph marginTop" v-if="coApplicant.clientObject">
            *z toho spoločných detí: <strong> {{
              mortgage.pocet_spolocnych_nezaopatrenych_deti
            }} </strong>
          </p>
          <div class="section-divider">
            <div>7. Odporúčané riešenia</div>
          </div>
          <p class="paragraph">
            S ohľadom na informácie poskytnuté Klientom v tomto zázname poskytuje Finančný agent
            klientovi označené informácie, odporúčania a odbornú pomoc v súvislosti s výberom vhodnej Finančnej služby.
            Finančný agent odporúča uzatvorenie zmluvy (zmlúv) o poskytnutí Finančnej služby v sektore poskytovania
            úverov nasledovne:
          </p>
          <div
            class="offer-list"
          >
            <div class="results-legend zok">
              <div class="break-40px"></div>
              <p>výška</p>
              <p>sadzba</p>
              <p>splátka</p>
              <p>poplatok</p>
              <p>splatnosť</p>
              <p>účet</p>
              <p>fixácia</p>
              <p>preplatenosť</p>
            </div>
            <div
              class="mortgage-result-broker zok"
              v-for="(application, index) in mortgage.applications"
              v-if="application.stage !== 'zrusena'"
              :key="index + 'applic'"
            >
              <div class="mortgage-parameters zok">
                <div class="banklogowrapper"><img
                  :src="application.bank.logo_square"
                  loading="lazy" :alt="application.bank.nazov" class="bank-logo"
                >{{application.bank.nazov}}
                </div>
                <div class="bold-2 wrap100px green">
                  {{ getTotalAmount(application) | dotTheAmount }} €
                </div>
                <div class="bold-2 wrap100px">
                  {{ application.sadzba_pozadovana }} %
                </div>
                <div class="bold-2 wrap100px green">
                  {{ application.splatka | toFloor(2) }} €
                </div>
                <div class="bold-2 wrap100px">
                  {{ (application.poplatok_za_poskytnutie ? application.poplatok_za_poskytnutie : 0) | ceil }} €
                </div>
                <div class="bold-2">
                  {{ application.splatnost }} R
                </div>
                <div class="bold-2 wrap100px">
                  {{ application.popl_za_vedenie_uctu | ceil }} €/m
                </div>
                <div class="bold-2 wrap50px">
                  {{ application.fix }} R
                </div>
                <div class="bold-2 wrap100px">
                  {{ application.preplatenost | ceil }} €
                </div>
              </div>
              <div class="discount-list"></div>
            </div>
          </div>
          <p class="paragraph">
            Zmluvy o poskytnutí Finančnej služby v sektore poskytovania úverov označené ako "vybraná"
            boli vybrané Klientom. Finančný agent prehlasuje, že vybrané zmluvy pre klienta sú vhodné lebo zodpovedajú
            potrebe a požiadavkám na zmluvu o Finančnej službe v zmysle bodu 2. tohto záznamu.
          </p>
          <div class="section-divider">
            <div>8. Prílohy odovzdané Klientovi</div>
          </div>
          <div class="w-layout-grid-c grid-iv-columns">
            <div class="content-block filter-primary"><img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4f_icons8-ok.svg"
              loading="lazy" alt="" class="checkmark">
              <div>Kópia záznamu</div>
            </div>
            <div class="content-block filter-primary"><img
              src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4f_icons8-ok.svg"
              loading="lazy" alt="" class="checkmark">
              <div>Žiadosť o úver</div>
            </div>
          </div>
          <div class="section-divider">
            <div>9. Ostatné ustanovenia</div>
          </div>
          <div class="subheading"><strong>Právny následok uzatvorenia sprostredkovanej Finančnej služby.</strong></div>
          <p class="paragraph">
            Klient si je vedomý, že tento záznam nie je návrhom na uzavretie zmluvy o poskytnutí
            Finančnej služby. Uzavretím zmluvy o Finančnej službe vznikajú Klientovi a Finančnej inštitúcii práva a
            povinnosti uvedené v zmluve o Finančnej službe a v príslušných súvisiacich všeobecných a osobitných
            podmienkach alebo obchodných podmienkach, ktoré tvoria neoddeliteľnú súčasť zmluvy o Finančnej službe. Ide
            najmä o práva a povinnosti zmluvných strán (najmä, nie však výlučne Klienta a Finančnej inštitúcie)
            súvisiace
            s finančným plnením, ktoré závisia od dohodnutého predmetu a obsahu zmluvy o Finančnej službe. Dohodnuté
            práva
            a povinnosti zmluvných strán ako aj ďalšie zmluvné podmienky je možné meniť len spôsobom a postupom
            dohodnutým
            v zmluve o Finančnej službe alebo na základe ďalšej dohody zmluvných strán. Predčasné ukončenie takejto
            zmluvy
            alebo porušenie dohodnutých zmluvných podmienok a povinností niektorou zo zmluvných strán, môže mať za
            následok, že si dotknutá zmluvná strana uplatní zmluvne dohodnutú sankciu za ich porušenie a/alebo že si
            dotknutá zmluvná strana uplatní zmluvne alebo všeobecne záväzným právnym predpisom stanovený nárok na
            náhradu
            škody a/alebo nárok na úrok z omeškania. Ak klient poruší pravidlá a postupy dohodnuté pred podpisom zmluvy
            o
            Finančnej službe, o ktorých bol vopred informovaný Finančnou inštitúciou alebo Finančným agentom a s ktorými
            vyslovil pred podpisom samotnej zmluvy súhlas, môže dôjsť aj k nenaplneniu očakávaných finančných cieľov a
            zámerov, kvôli ktorým bola zmluva uzavretá.
          </p>
          <div class="subheading"><strong>Informácie k rizikám, o ktorých bol klient informovaný</strong></div>
          <p class="paragraph">
            V prípade ak Klient, t. j. dlžník/spoludlžník alebo ručiteľ bude v omeškaní so splátkou,
            môže dôjsť k mimoriadnej splatnosti zmluvy o Finančnej službe - úveru/pôžičky; nesplácanie úveru/pôžičky
            načas
            môže mať za následok zápis do úverového registra, následne môže mať Klient v budúcnosti problém pri žiadaní
            o
            následný úver/pôžičku; v prípade ak Klient si nebude načas plniť svoje záväzky plynúce z uzatvorenej zmluvy
            o
            Finančnej službe, t. j. úvere / pôžičke, prechádzajú tieto záväzky na ručiteľa; predčasné splatenie celého
            úveru / jeho časti, môže byť spoplatnené (podľa sadzobníka) Finančnej inštitúcie.
          </p>
          <div class="subheading"><strong>Vyhlásenia Klienta</strong></div>
          <p class="paragraph">
            Klient potvrdzuje že informáciám porozumel a všetky potrebné informácie mu ako Klientovi
            boli poskytnuté v dostatočnom časovom predstihu pred konkrétnym rozhodnutím tykajúcim sa Finančnej služby a
            tieto boli postačujúce k prijatiu rozhodnutia tykajúceho sa Finančnej služby. <br><br>Klient bol oboznámený
            s
            rizikami spojenými s odporúčanými i zvolenými Finančnými službami, porozumel im a je schopný tieto riziká
            zvládnuť. Klient si je vedomý svojej finančnej situácie a finančných záväzkov, ktoré mu budú vyplývať zo
            sprostredkovanej Finančnej služby a zároveň vyhlasuje, že jeho finančná situácia umožňuje plnenie záväzkov
            zo
            zmluvy o poskytovaní Finančnej služby, ktorú si zvolil, bez negatívnych dopadov.<br><br>Klient berie na
            vedomie, že ak neuviedol na stretnutí s Finančným agentom a/alebo nevyplnil niektoré vstupné informácie a
            ďalšie informácie uvedené tomto zázname podľa § 35 zákona o FSa/alebo § 73f a/alebo § 73g zákona o CP, má sa
            za to, že takéto informácie poskytnúť odmieta, avšak trvá na uzavretí zmluvy o poskytnutí Finančnej služby,
            pričom nemusí byť možné správne určiť, či sú odporúčané Finančné služby tohto záznamu pre Klienta vhodné.
            Klient berie na vedomie, že v situácii podľa predchádzajúcej vety, Finančný agent nenesie zodpovednosť za
            neprimeranosť rizík a nevhodnosť zvolenej Finančnej služby.<br><br>Klient potvrdzuje, že pred vykonaním
            Finančného sprostredkovania bol informovaný, že finančná služba je odplatná a Finančný agent za ňu dostáva
            peňažné plnenia vo forme provízií, príspevkov a odmien podľa províznych podmienok SFA a môže prijímať aj
            nepeňažné plnenie vo forme výhier zo súťaží organizovaných SFA. Bol informovaný o možnosti informovať sa na
            výšku peňažného resp. nepeňažného plnenia a v prípade požiadavky bol vyčerpávajúcim, zrozumiteľným, presným
            a
            jednoznačným spôsobom informovaný o výške. Potvrdzujem, že ma Finančný agent presným a zrozumiteľným
            spôsobom
            informoval o všetkých finančných nákladoch súvisiacich so sprostredkovanou Finančnou službou a že mi
            poskytol
            informácie o všetkých skutočnostiach nevyhnutných k môjmu rozhodnutiu v zmysle §3 zákona o FS. Klient
            vyhlasuje, že berie na vedomie, že SFA ani PFA nenesie zodpovednosť za neprimeranosť rizík a nevhodnosť
            zvolenej Finančnej služby.<br><br>Klient vyhlasuje, že tento obchod vykonáva vo vlastnom mene, na
            vlastný účet, finančné prostriedky použité pri tomto obchode sú jeho vlastníctvom, pochádzajú z jeho
            vlastných
            zdrojov, je konečný užívateľ výhod, nie je politicky exponovanou osobou. Vyhlásenie uvedené v
            predchádzajúcej
            vete a v definícii pojmov nie sú účinné, ak Klient v časti potreby a požiadavky Klienta vyhlásil opak a
            tieto
            skutočnosti boli zaznamenané do príslušnej prílohy stanovenej Smernicou o AML.<br><br>Klient potvrdzuje, že
            Finančný agent si splnil povinnosť zistiť a zaznamenať požiadavky a potreby Klienta, jeho skúsenosti a
            znalosti týkajúce sa príslušnej Finančnej služby a informácie o jeho finančnej situácii, a to s ohľadom na
            povahu Finančnej služby, ktorá je predmetom finančného sprostredkovania.
          </p>
          <div class="subheading"><strong>Ochrana osobných údajov</strong></div>
          <p class="paragraph">
            Klient potvrdzuje, že mu boli poskytnuté informácie o tom, že SFA a PFA ako
            prevádzkovateľ,
            spracúvajú osobné údaje uvedené v tomto Zázname na účely vykonávania finančného sprostredkovania, v rozsahu
            a
            za podmienok stanovených zákonom, a to najmä zákonom o FS a v súlade s nariadením Európskeho parlamentu a
            Rady
            (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov,
            ktorým sa zrušuje smernica 95/46/ES, čo je nevyhnutné na splnenie zákonnej povinnosť SFA a PFA. Osobné údaje
            sú uchovávané po dobu najmenej desiatich rokov od začiatku platnosti zmluvy o poskytnutí Finančnej
            služby.Práva
            dotknutej osoby: právo požadovať prístup k osobným údajom, ktoré sa týkajú Klienta, právo na opravu osobných
            údajov, právo na vymazanie a/alebo obmedzenie spracúvania osobných údajov, právo na prenositeľnosť osobných
            údajov, právo podať sťažnosť na spracúvanie osobných údajov orgánu dozoru – Úradu na ochranu osobných údajov
            Slovenskej republiky. Podrobné informácie o spracúvaní osobných údajov sú uvedené v Informáciách o ochrane
            osobných údajov, ktoré sú zverejnené aj na webovej Stránke SFA.
          </p>
          <div class="subheading"><strong>Spôsob a systém ochrany pred zlyhaním Finančnej inštitúcie</strong></div>
          <p class="paragraph">
            Systém ochrany pred zlyhaním finančnej inštitúcie je zabezpečený prostredníctvom dohľadu
            NBS a iných súvisiacich mechanizmov. Cieľom dohľadu nad finančným trhom je prispievať k stabilite finančného
            trhu ako celku, ako aj k bezpečnému a zdravému fungovaniu finančného trhu, ochrany klientov a rešpektovania
            pravidiel hospodárskej súťaže. Takto rizikovo orientovaný dohľad zameraný na analýzu a vyhodnocovanie
            kvantitatívnych ukazovateľov dohliadaných subjektov, medzi ktoré napr. patrí splnenie kapitálových
            požiadaviek, vyššia kvalita ohodnocovania rizík, efektívna alokácia kapitálu, je vykonávaný nad
            bankovníctvom,
            platobnými inštitúciami, inštitúciami elektronických peňazí, trhom cenných papierov, poisťovníctvom,
            dôchodkovým sporením, finančným sprostredkovaním a finančným poradenstvom. Funkčnými mechanizmami ochrany
            pred
            zlyhaním finančnej inštitúcie sú Fond na ochranu vkladov zriadený zákonom č.118/1996 Z.z. o ochrane vkladov
            v
            planom znení a Garančný fond investícií zriadený zákonom č.566/2001 Z.z. o cenných papieroch a investičných
            službách v platnom znení, ktoré poskytujú náhrady za nedostupné klientske vklady a majetok.
          </p>
          <div class="subheading"><strong>Postup pri podávaní sťažností</strong></div>
          <p class="paragraph">
            Sťažnosť na činnosť SFA alebo PFA môže Klient podať osobne, prostredníctvom pošty alebo
            kuriérnej služby priamo v sídle spoločnosti SFA ale PFA, alebo prostredníctvom elektronickej pošty na adresu
            SFA alebo PFA uvedenú v záhlaví tohto záznamu. Pre SFA a PFA vyplýva zmluvná povinnosť vzájomného informovania sa o skutočnosti, že bola
            podaná
            sťažnosť v zmysle tohto odstavca. Lehota na vybavenie sťažnosti je 30 dní odo dňa doručenia sťažnosti. V
            prípadoch sťažností náročných na prešetrenie môže spoločnosť SFA alebo PFA predĺžiť lehotu na jej vybavenie
            o
            ďalších 30 dní. O každom predĺžení lehoty na vybavenie a dôvodoch predĺženia je Finančný agent povinný
            písomne
            upovedomiť Klienta. Osobitnými predpismi upravujúcimi mimosúdne vyrovnanie sporov vyplývajúcich z Finančného
            sprostredkovania sa rozumejú zákon č. 244/2002 Z.z. o rozhodcovskom konaní v znení zákona č. 521/2005 Z.z. a
            zákon č. 420/2004 Z.z. o mediácii a o doplnení niektorých zákonov, zákon č. 335/2014 Z.z. o spotrebiteľskom
            rozhodcovskom konaní a zákon č. 391/2015 Z.z. o alternatívnom riešení sporov. Klient sa môže obrátiť na
            orgán
            alternatívneho riešenia sporov v sektore poistenia alebo zaistenia, a to najmä na poisťovacieho ombudsmana
            zriadeného pri Slovenskej asociácii poisťovní (SLASPO).
          </p>
          <div class="subheading"><strong>Ďalšie informácie</strong></div>
          <p class="paragraph">
            SFA ako samostatný finančný agent spolupracuje s viacerými finančnými inštitúciami na
            základe nevýhradných zmlúv. Na požiadanie klienta je SFA, respektíve PFA povinný oznámiť Klientovi obchodné
            mená finančných inštitúcií, s ktorými má SFA uzatvorené zmluvy o Finančnom sprostredkovaní. Obchodné mená
            spoločností Finančných inštitúcií sú uvedené v registri NBS: https://regfap.nbs.sk/search.php.<br><br>Finančný
            agent neúčtuje Klientom žiadne dodatočné poplatky či náklady, spojené s finančným sprostredkovaním. 
            
            <!-- zobrazovať len pri SFA = Finportal -->

            Znalecký posudok ponúkaný na webovej stránke PFA (<a href="http://www.bankari.sk">www.bankari.sk</a>) je službou
            sprostredkovania mimo zákona o finančnom sprostredkovaní a súvisí s realitnou činnosťou PFA pod obchodným
            názvom osobnimakleri.sk
          </p>
          <div class="subheading"><strong>Majetková prepojenie SFA od 01.04.2022</strong></div>
          <p class="paragraph">
            SFA nemá kvalifikovanú účasť na základnom imaní alebo hlasovacích právach žiadneho svojho
            PFA a ani žiadnej Finančnej inštitúcie, s ktorou má uzavretú zmluvu, predmetom ktorej je vykonávanie
            Finančného sprostredkovania (ďalej „ZoFS“). Žiadna Finančná inštitúcia, s ktorou má SFA uzavretú ZoFS, nemá
            kvalifikovanú účasť na základnom imaní alebo hlasovacích právach Finančného agenta. NN Continental Europe
            Holdings B.V. so sídlom: Schenkkade 65, Den Haag, 2595 AS, Holandsko má kvalifikovanú účasť na základom
            imaní
            a hlasovacích právach SFA, pričom je zároveň ovládajúcou osobou spoločnosti NN Životná poisťovňa, a.s.,
            Jesenského 4/C, 811 02 Bratislava, IČO: 35 691 999 (ďalej „NN ŽP“ ), spoločnosti NN Tatry – Sympatia,
            d.d.s.,
            a.s., Jesenského 4/C, 811 02 Bratislava, IČO: 35 976 853 (ďalej „NN DDS“) a spoločnosti NN dôchodková
            správcovská spoločnosť, a.s., Jesenského 4/C, 811 02 Bratislava, IČO: 35 902 981 (ďalej „NN DSS“). SFA FP má
            s
            NN ŽP, NN DDS a NN DSS uzatvorenú ZoFS.<br><br>PFA nemá kvalifikovanú účasť na základnom imaní alebo
            hlasovacích právach SFA. Podľa informácií, ktoré má SFA FP k dispozícii, žiadny PFA nemá kvalifikovanú účasť
            na základnom imaní ani na hlasovacích právach ktorejkoľvek z Finančných inštitúcii, s ktorými má SFA
            uzatvorenú ZoFS. Podľa informácií, ktoré má SFA k dispozícii, žiadna finančná Inštitúcia, resp. osoby
            ovládajúce Finančné inštitúcie, s ktorými má SFA FP uzatvorenú ZoFS, nemá kvalifikovanú účasť na základnom
            imaní ani na hlasovacích právach ktoréhokoľvek PFA, ktorý je právnickou osobou.
          </p>
          <div class="section-divider">
            <div>10. Potvrdenie klienta</div>
          </div>
          <p class="paragraph">
            Klient svojim podpisom potvrdzuje správnosť, pravdivosť a úplnosť údajov spracovaných v
            tomto Zázname, rovnako potvrdzuje, že od Finančného agenta prevzal kópiu tohto záznamu (vrátane príloh). Je
            si
            vedomý, že chýbajúce, neúplné, nepravdivé alebo neuvedené údaje môžu viesť k negatívnym dôsledkom pri
            zostavení ponuky produktu. Na znak súhlasu s uvedenými údajmi a vyhláseniami na všetkých stranách Záznamu
            Klient tento Záznam podpisuje.<br><br>Klient potvrdzuje, že bol informovaný o spôsobe výpočtu poplatku
            a
            iných nákladov súvisiacich so sprostredkovanou zmluvou Finančnej služby úveru na bývanie. Klient bol
            informovaný, že na základe písomnej požiadavky má Finančný agent povinnosť informovať Klienta o rozdieloch
            vo
            výškach peňažného plnenia v percentách, ktoré prijíma Finančný agent za Finančné sprostredkovanie od jedného
            alebo viacerých veriteľov. Informácia v zmysle § 21 ods. 5 písm. b) zákona č. 90/2016 Z.z.: predpokladaná
            výška peňažného plnenia, ktoré má SFA zaplatiť PFA za sprostredkovanie vyššie uvedených úverov je 1%. _ z
            výšky sprostredkovaného úveru.
          </p>
          <div class="w-layout-grid-c grid-podpisy">
            <div class="signature-block">
              <div>V Bratislava dňa {{getConvertedDate(applicant.clientObject.client_profile.created) }}</div>
              <div id="podpis_ziadate">za Klienta <strong>{{ applicant.clientObject.first_name }}
                {{ applicant.clientObject.last_name }}</strong></div>
            </div>
            <div class="signature-block" v-if="coApplicant.clientObject">
              <div>V Bratislava dňa {{ getConvertedDate(applicant.clientObject.client_profile.created) }}</div>
              <div id="podpis_spoluziadate">za Spoluklienta <strong>{{ coApplicant.clientObject.first_name }}
                {{ coApplicant.clientObject.last_name }}</strong></div>
            </div>
            <div class="signature-block">
              <div>V Bratislava dňa {{ getConvertedDate(applicant.clientObject.client_profile.created) }}</div>
              <div id="broker">za PFA <strong>{{ broker.first_name }} {{ broker.last_name }}</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesConvert from "@/mixins/imagesConvert";
import propertyFilters from "@/mixins/propertyFilters";
import translateFromDBValue from "@/mixins/translateFromDBValue";
import ConvertApi from "convertapi-js";
import {getFile, getStaffProfile, getSfa} from "@/services/brokerService";
import mortgageUtils from "@/mixins/mortgageUtils";
import {mapGetters} from "vuex";

export default {
  name: "ClientNeedsGenerator",
  props: {
    applicant: Object,
    coApplicant: Object,
    properties: Object,
    mortgage: Object,
    broker: Object,
  },
  async created() {
    this.loading = true;
    const purpose = this.getMortgagePurpose();
    switch (purpose) {
      case "nadobudnutie":
        this.purposeLabel = "na kúpu nehnuteľnosti";
        break;
      case "refinancovanie":
        this.purposeLabel = " pre refinancovanie Vásho úveru";
        break;
      case "bezucel":
        this.purposeLabel = "pre americkú hypotéku";
        break;
      default:
        this.purposeLabel = "na kúpu nehnuteľnosti";
    }
    const offers = this.$store.getters["mortgageDetailState/getOffers"];
    for (const offer of offers) {
      if (!offer.pinned) continue;
      this.prefferedBanks.push(offer.bank.name);
    }
    this.foundedProperties = this.filterFoundedPropertiesById(this.properties);
    this.financedProperties = this.filterFinancedPropertiesById(this.properties);
    this.clientAmounts = this.$store.getters["mortgageDetailState/getClientAmounts"];
    const brokerId = (this.$store.getters["userState/getMe"]).id;
    this.staffProfil = await getStaffProfile(brokerId);
    this.staffProfil.staff_profile.sfa = await getSfa(this.staffProfil.staff_profile.sfa);
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      offersGetter: "mortgageDetailState/getOffers",
    }),
  },
  mixins: [
    propertyFilters,
    imagesConvert,
    translateFromDBValue,
    mortgageUtils,
  ],
  watch: {
    offersGetter(newOffers) {
      this.prefferedBanks = [];
      for (const offer of newOffers) {
        if (!offer.pinned) continue;
        this.prefferedBanks.push(offer.bank.name);
      }
    }
  },
  methods: {
    sumAmounts(object) {
      let sum = 0;
      for (const value of Object.values(object)) {
        sum += value;
      }
      return sum;
    },
    pickClientOrBrokerAmount(key) {
      const historyObject = JSON.parse(JSON.stringify(this.mortgage.offers_filter_history.broker.history[0]));
      delete historyObject.fix;
      delete historyObject.date;
      if (key === "all") {
        if (!this.areAmountsZero(this.clientAmounts)) return this.clientAmounts;
        return historyObject;
      } else if (key === "suma_refinancovanie") {
        if (this.clientAmounts.suma_refinancovanie === 0) return historyObject.suma_refinancovanie;
        return this.clientAmounts.suma_refinancovanie;
      } else if (key === "suma_nadobudnutie") {
        if (this.clientAmounts.suma_nadobudnutie === 0) return historyObject.suma_nadobudnutie;
        return this.clientAmounts.suma_nadobudnutie;
      } else if (key === "suma_bezucel") {
        if (this.clientAmounts.suma_bezucel === 0) return historyObject.suma_bezucel;
        return this.clientAmounts.suma_bezucel;
      }
    },
    areAmountsZero(object) {
      for (const value of Object.values(object)) {
        if (value === 0) continue;
        return false;
      }
      return true;
    },
    filterActive(array) {
      return array.filter((item) => item.is_active);
    },
    filterVisible(array) {
      return array.filter((item) => item.is_visible || item.refinancovany_hypotekou);
    },
    getDstiCalculation(incomes, index) {
      let dstiSum = 0;
      for (let i = 0; i < (index + 1); i++) {
        dstiSum += incomes[i].dti.extra?.prijem_mo ? incomes[i].dti.extra.prijem_mo : 0;
      }
      return dstiSum;
    },
    getInformationAboutIncome(income, index) {
      if(this.employment.includes(income.druh)) {
        return `*údaje o príme ${ index + 1 }
        - Zamestnávateľ: ${ this.addComma(income.zamestnavatel?.name) } ${ income.zamestnavatel?.address_city }
        ${ income.zamestnavatel?.address_street }
        ${ income.zamestnavatel?.address_number } ${ this.addComma(income.zamestnavatel?.address_zip) }
        IČO: ${ income.zamestnavatel?.ico }`
      }
      else if (this.rental.includes(income.druh)) {
        let dateFrom = new Date(income.zamestnanie_od);
        dateFrom = dateFrom.toLocaleDateString('zh-Hans-CN', {
          year: 'numeric', month: 'numeric', day: 'numeric'
        });
        return `*údaje o príme ${ index + 1 }
        - Prenájomtrvá od: ${ dateFrom }`
      } else if (this.maternity.includes(income.druh)) {
        const bankVars = this.$store.getters["formStore/getBankVars"];
        if (bankVars.rodicovsky_prispevok_ak_materska === income.suma) {
          return `*údaje o príme ${ index + 1 } - Materská bola vyplácaná`
        } else if (bankVars.rodicovsky_prispevok_ak_nie_materska === income.suma) {
          return `*údaje o príme ${ index + 1 } - Materská nebola vyplácaná`
        }
      }
    },
    addComma(string) {
      if (!string) return "";
      return string.concat(",")
    },
    doesClientHaveDebts(client) {
      for (const debt of client.debts) {
        if (!debt.is_visible && debt.refinancovany_hypotekou) continue;
        return true;
      }
      return false;
    },
    getTotalAmount(application) {
      return application.backEndLimitCorrected ? application.suma_nadobudnutie + application.suma_bezucel + application.suma_refinancovanie : application.correctedAmount;
    },
    getMaxFromApplications(key) {
      let max = 0;
      for (const application of Object.values(this.mortgage.applications)) {
        if (application[key] < max) continue;
        max = application[key];
      }
      return max ? max : 0;
    },
    getAge(date) {
      const dob = new Date(date);
      const month_diff = Date.now() - dob.getTime();
      const age_dt = new Date(month_diff);
      const year = age_dt.getUTCFullYear();
      return Math.abs(year - 1970);
    },
    sumPropertiesValues(type) {
      let sum = 0;
      for (const property of this[type]) {
        sum += property.hodnota;
      }
      return sum
    },
    translateFromDbValue(type, key, value) {
      return this.$store.getters["formStore/translateBEValue"](type, key, value);
    },
    getConvertedDate(pDate) {
      const date = new Date(pDate);
      return date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      });
    },
    hideClientNeedsGenerator() {
      this.$store.commit("popupManagerState/toggleClientNeeds", false);
    },
    async generate() {
      this.generatingPdf = true;
      const generatedNeedsElement = document.getElementById("generatedNeedsPart");
      const htmlString =
        `<html>
          <head>
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>

              @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;800;900&display=swap');
              
              @media print{
                margin: 0px;
              }

              body{
                font-family: Poppins, sans-serif !important;
                font-size: 20pt;
                color: #0A1953;
              }

              .pagebreak {
                  clear: both;
                  page-break-after: always;
              }

              a{
                color: #00a95f;
                text-decoration: none;
              }

              .bold{
                font-weight: 900;
              }

              .header2 {
                display: flex;
                height: 500px;
                margin-right: -40px;
                margin-left: -40px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-image: url("https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f32_ZoPK%20header%20image-min.png");
                background-position: 50% 50%;
                background-size: cover;
                background-repeat: no-repeat;
              }

              .limitwidth{
                max-width: 600px;
                text-align: center;
              }

              .logo {
                width: 200px;
                height: 40px;
                margin-bottom: 20px;
                background-image: url("https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2e_logo%20ponuka%20financovania.svg");
                background-position: 50% 50%;
                background-size: contain;
                background-repeat: no-repeat;
              }

              .bank-logo{
                width: 35px;
                height: 35px;
                border-radius: 5px;
                -o-object-fit: contain;
                object-fit: contain;
                display: none;
              }

              .banklogowrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 15px;
                width: 50px;
              }

              .section-divider {
                margin: 20px -10px;
                padding: 20px;
                background-color: #eef5f7;
                color: #00a95f;
                font-size: 28px !important;
                font-weight: 500;
              }

              .bold-2 {
                font-weight: 700;
              }

              .bold-2.wrap50px {
                width: 50px;
                text-align: center;
              }

              .bold-2.wrap100px {
                text-align: center;
              }

              .offer-list {
                margin-bottom: 20px;
              }

              .checkmark {
                width: 20px;
                margin-right: 10px;
              }

              .discount-list {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                width: auto;
                margin-top: 0px;
                padding: 0px;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
              }

              .upper-index {
                position: relative;
                top: auto;
                bottom: 0.8em;
                margin-left: 2px;
                font-size: 10pt;
                line-height: 10px;
              }

              .page-wrapper {
                min-height: 100vh;
              }

              .legend-block {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                background-color: #258b66;
                color: #f8f9fa;
                font-weight: 500;
              }

              .grid-iv-columns {
                display: grid;
                justify-items: stretch;
                -webkit-box-align: stretch;
                -webkit-align-items: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -webkit-align-content: center;
                -ms-flex-line-pack: center;
                align-content: center;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                -ms-grid-columns: 0.25fr 0.25fr 0.25fr 0.25fr !important;
                grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr !important;
                -ms-grid-rows: auto;
                grid-template-rows: auto;
                border: 1px none #000;
                background-color: #f8f9fa;
              }

              .subheading {
                font-size: 18pt;
                font-weight: 700;
              }

              .mortgage-parameters {
                display: -ms-grid;
                display: grid;
                min-width: 0px;
                margin-bottom: 0px;
                padding: 0px;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                justify-items: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                grid-auto-columns: 1fr;
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
                -ms-grid-rows: auto;
                grid-template-rows: auto;
                border-radius: 10px;
                background-color: transparent;
              }

              .mortgage-parameters.zok {
                -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
              }

              .mortgage-result-broker {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                min-width: 950px;
                margin-bottom: 5px;
                padding: 20px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: stretch;
                -webkit-align-items: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                border-radius: 10px;
                background-color: #fff;
              }

              .mortgage-result-broker.zok {
                background-color: #f8f9fa;
                box-shadow: none !important;
              }

              .logo-div {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                height: 50px;
                margin-top: 20px;
                margin-bottom: 20px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: start;
                -webkit-align-items: flex-start;
                -ms-flex-align: start;
                align-items: flex-start;
                background-repeat: no-repeat;
                background-image: url('https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912e49_Bankari%20logo%20inverted%20transparent%20extrabold.svg');
                background-size: contain;
              }

              .signature-block {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                height: 200px;
                padding: 20px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;
                background-color: #f8f9fa;
              }

              .dynamic-data-block {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                margin-top: 20px;
                margin-bottom: 20px;
                padding: 10px 20px;
                background-color: #edf5f7;
                font-weight: 700;
              }

              .grid-iii-columns {
                display: grid;
                justify-items: stretch;
                -webkit-box-align: stretch;
                -webkit-align-items: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -webkit-align-content: center;
                -ms-flex-line-pack: center;
                align-content: center;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                -ms-grid-columns: 0.5fr 0.25fr 0.25fr;
                grid-template-columns: 0.5fr 0.25fr 0.25fr;
                -ms-grid-rows: auto auto;
                grid-template-rows: auto auto;
                border: 1px none #000;
                background-color: #f8f9fa;
              }

              .grid-ii-columns {
                  display: grid;
                  justify-items: stretch;
                  -webkit-box-align: stretch;
                  -webkit-align-items: stretch;
                  -ms-flex-align: stretch;
                  align-items: stretch;
                  -webkit-align-content: center;
                  -ms-flex-line-pack: center;
                  align-content: center;
                  grid-column-gap: 0px;
                  grid-row-gap: 0px;
                  -ms-grid-columns: 0.5fr 0.5fr;
                  grid-template-columns: 0.5fr 0.5fr;
                  -ms-grid-rows: auto auto;
                  grid-template-rows: auto auto;
                  border: 1px none #000;
                  background-color: #f8f9fa;
                }

              .container-zok {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding-top: 40px;
                padding-bottom: 40px;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
              }

              .results-legend {
                display: -ms-grid;
                display: grid;
                min-width: 950px;
                margin-top: 20px;
                margin-bottom: 20px;
                padding-right: 20px;
                padding-left: 20px;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                justify-items: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                grid-auto-columns: 1fr;
                grid-column-gap: 16px;
                grid-row-gap: 16px;
                -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
                -ms-grid-rows: auto;
                grid-template-rows: auto;
              }

              .results-legend.zok {
                -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                text-align: center;
              }

              .content-block {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                background-color: transparent;
                color: #258b66;
                font-weight: 700;
              }

              .break-40px {
                width: 40px;
              }

              .grid-podpisy {
                display: grid;
                -ms-grid-columns: 0.5fr 0.5fr;
                grid-template-columns: 0.5fr 0.5fr;
                column-gap: 20px;
                row-gap: 20px;
                -ms-grid-rows: auto auto;
                grid-template-rows: auto auto;
              }

              .paragraph {
                margin-bottom: 20px;
                text-align: justify;
                font-weight: 500;
              }

              .paragraph.marginTop{
                margin-top: 20px;
              }

              .header {
                height: 50px;
                margin-bottom: 20px;
                background-image: linear-gradient(315deg, #20a134, #258b66);
              }

              @media screen and (max-width: 991px) {
                .mortgage-parameters {
                  min-width: 0px;
                }
              }

              @media screen and (max-width: 767px) {
                .upper-index {
                  position: relative;
                  top: -5px;
                }
              }

              @media screen and (max-width: 479px) {
                .mortgage-parameters {
                  grid-column-gap: 5px;
                  grid-row-gap: 5px;
                }

                .results-legend {
                  grid-column-gap: 5px;
                  grid-row-gap: 5px;
                }
              }
            </style>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
          </head>
          <body>
            ${generatedNeedsElement.innerHTML}
          </body>
        </html>`;
      const htmlBlob = new Blob([htmlString], {type: 'text/html'});
      const file = new File([htmlBlob], 'myfile.html');
      let convertApi = ConvertApi.auth("token_pbmcXbr4");
      let params = convertApi.createParams();
      params.add('file', file);
      const pdfGen = await convertApi.convert('html', 'pdf', params);
      const urlOfFile = pdfGen.files[0].Url;
      const generatedPdf = (await getFile(urlOfFile)).data;
      this.download(generatedPdf);
      this.generatingPdf = false;
    },
    download(file) {
      const objectUrl = URL.createObjectURL(file);
      const pdfWindow = window.open(objectUrl, "_blank");
      pdfWindow.addEventListener("beforeunload", () => {
        if (pdfWindow.closed) {
          URL.revokeObjectURL(objectUrl);
        }
      });
    }
  },
  data: () => ({
    staffProfil: null,
    loading: true,
    purposeLabel: null,
    prefferedBanks: [],
    generatingPdf: false,
    offers: null,
    employment: [
      "trvaly_pracovny_pomer",
      "pracovny_pomer_na_cas_urcity",
      "pracovny_pomer_na_neurcito",
      "dohoda_o_pracovnej_cinnosti",
      "pracovny_pomer_cez_agenturu",
      "pracovny_pomer_vo_vlastnej_firme",
      "prijem_zivnostnika",
      "prijem_z_sro",
    ],
    rental: [
      "prijem_z_prenajmu_nehnutelnosti",
    ],
    maternity: [
      "materska_rodicovsky_prispevok",
    ],
    foundedProperties: [],
    financedProperties: [],
    clientAmounts: {},
  }),
}
</script>

<style lang="scss" scoped>

.section-divider {
  margin: 20px -10px;
  padding: 20px;
  background-color: #f7ebf1;
  color: #8c2a5e;
  font-size: 18px;
  font-weight: 700;
}

a{
  color: #8c2a5e;
  text-decoration: none;
  font-weight: 500;
}

.header2 {
  display: flex;
  height: 350px;
  margin-right: -40px;
  margin-left: -40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f32_ZoPK%20header%20image-min.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.limitwidth{
  max-width: 500px;
  text-align: center;
}

.logo {
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
  background-image: url("https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f2e_logo%20ponuka%20financovania.svg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.bold-2 {
  font-weight: 700;
}

.banklogowrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  width: 50px;
}

.bold-2.wrap50px {
  width: 50px;
  text-align: center;
}

.bold-2.wrap100px {
  width: 90px;
  text-align: center;
}

.offer-list {
  margin-bottom: 20px;
}

.checkmark {
  width: 20px;
  margin-right: 10px;
}

.discount-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 0px;
  padding: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.upper-index {
  position: relative;
  top: auto;
  bottom: 0.8em;
  margin-left: 2px;
  font-size: 7px;
  line-height: 10px;
}

.page-wrapper {
  min-height: 100vh;
}

.legend-block {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: #8c2a5e;
  color: #f8f9fa;
  font-weight: 500;
}

.grid-iv-columns {
  display: grid;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.25fr 0.25fr 0.25fr 0.25fr !important;
  grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr !important;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border: 1px none #000;
  background-color: #f8f9fa;
}

.subheading {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.mortgage-parameters {
  display: -ms-grid;
  display: grid;
  min-width: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #f8f9fa;
}

.mortgage-parameters.zok {
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.mortgage-result-broker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 950px;
  margin-bottom: 5px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 10px;
  background-color: #fff;
}

.mortgage-result-broker.zok {
  background-color: #f8f9fa;
  box-shadow: none !important;
}

.logo-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912e49_Bankari%20logo%20inverted%20transparent%20extrabold.svg');
  background-repeat: no-repeat;
}

.signature-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #f8f9fa;
}

.dynamic-data-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #edf5f7;
  font-weight: 700;
}

.grid-iii-columns {
  display: grid;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.5fr 0.25fr 0.25fr;
  grid-template-columns: 0.5fr 0.25fr 0.25fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border: 1px none #000;
  background-color: #f8f9fa;
}

.grid-ii-columns {
  display: grid;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border: 1px none #000;
  background-color: #f8f9fa;
}

.container-zok {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.results-legend {
  display: -ms-grid;
  display: grid;
  min-width: 950px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.results-legend.zok {
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}

.content-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  color: #8c2a5e;
  font-weight: 700;
}

.break-40px {
  width: 40px;
}

.grid-podpisy {
  display: grid;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: 20px;
  row-gap: 20px;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.paragraph {
  margin-bottom: 20px;
  text-align: justify;
}

.paragraph.marginTop{
  margin-top: 20px;
}

.header {
  height: 50px;
  margin-bottom: 20px;
  background-image: linear-gradient(315deg, #20a134, #258b66);
}

@media screen and (max-width: 991px) {
  .mortgage-parameters {
    min-width: 0px;
  }
}

@media screen and (max-width: 767px) {
  .upper-index {
    position: relative;
    top: -5px;
  }
}

@media screen and (max-width: 479px) {
  .mortgage-parameters {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .results-legend {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
}
</style>