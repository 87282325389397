import translateFromDBValue from "@/mixins/translateFromDBValue";
import propertyFilter from "@/mixins/propertyFilters";
import {getFile} from "@/services/brokerService";
import ConvertApi from "convertapi-js";


export default {
  methods: {
    async downloadFilesAsZip(files, nameOfFile) {
      await this.mapFilesFromFilesUrlToObjects(files);
      const zippedFilesUrl = (await this.convertFilesToZip(files)).dto.Files[0].Url;
      const zippedFilesBlobObject = (await getFile(zippedFilesUrl)).data;
      this.downloadZip(zippedFilesBlobObject, nameOfFile);
    },
    downloadZip(blob, nameOfFile) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = nameOfFile;
      a.target = "_blank";
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    async convertFilesToZip(filesToZip) {
      const filesObjects = filesToZip.map((fileObject) => fileObject.fileObject);
      let convertApi = ConvertApi.auth('token_pbmcXbr4')
      let params = convertApi.createParams()
      for (const [index, fileObject] of filesObjects.entries()) {
        params.add(`Files[${index}]`, fileObject);
      }
      return await convertApi.convert('any', 'zip', params)
    },
    async mapFilesFromFilesUrlToObjects(uploadedCloudFilesObjects) {
      const promises = [];
      for (const cloudFileObject of uploadedCloudFilesObjects) {
        promises.push({func: getFile, url: cloudFileObject.file});
      }
      // True for retrieving raw data (also url with blob returned from response for further mapping of files to correct ones)
      const retrievedFiles = await Promise.all(promises.map((promise) => promise.func(promise.url, true)));
      for (const cloudFileObject of uploadedCloudFilesObjects) {
        for (const retrievedFile of retrievedFiles) {
          if (retrievedFile.url !== cloudFileObject.file) continue;
          const fileType = this.getCloudFileTypeByExtension(cloudFileObject.name);
          cloudFileObject.fileObject = new File([retrievedFile.fileData], cloudFileObject.name, {
            type: fileType
          })
        }
      }
    },
    getCloudFileTypeByExtension(fileName) {
      const fileExtension = fileName.match(/\.[0-9a-z]+$/i)[0];
      if (fileExtension === ".doc") return "application/msword";
      else if (fileExtension === ".docx") return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      else if (fileExtension === ".zip") return "application/zip";
      return "application/pdf";
    },
    collectUploadedFilesFromMortgageCloud() {
      const cloud = this.$store.getters["mortgageDetailCloud/getClouds"];
      let uploadedCloudFiles = [];
      for (const cloudElement of cloud) {
        if (!cloudElement.missingFiles) continue;
        for (const cloudMissingFiles of cloudElement.missingFiles) {
          if (!cloudMissingFiles.uploaded) continue;
          uploadedCloudFiles = [...uploadedCloudFiles, ...cloudMissingFiles.uploaded];
        }
      }
      return uploadedCloudFiles
    },
    constructClient(client, applicant, mortgageId="_") {
      if (!client) return null;
      return {
        cloudId: client.cloud,
        objectId: client.id,
        label: client.first_name ? client.first_name + " " + client.last_name : client.id,
        image: applicant ? require("@/images/avatar_applicant.png") : require("@/images/avatar_coapplicant.png"),
        subField: false,
        cloudMeta: {
          [mortgageId]: {
            clouds:[{
              isActive: true,
              invisible: false,
              id: client.cloud,
              type_label: `Cloud ${applicant ? "žiadateľa" : "spolužiadateľa"}`,
              label: `${client.first_name} ${client.last_name}`,
              files: [],
            }]
          },
        },
        missingFiles: [],
      }
    },
    constructDebts(debts, mortgageId="_") {
      if (debts.length === 0) return [];
      const translator = translateFromDBValue.methods;
      const clouds = []
      for (const debt of debts) {
        clouds.push({
          cloudId: debt.cloud,
          objectId: debt.id,
          label: "Výdavok " + debt.clientInfo.type.toLowerCase() + "a",
          image: require("@/images/debt icon.png"),
          subField: true,
          subLabel: translator.translateDebtType(debt.typ) + ` ${debt.cislo_uveru ? "č. " + debt.cislo_uveru : ""}`,
          // subLabel: `${translator.translateDebtType(debt.typ)} ${["spotrebny_uver", "hypoteky"].includes(debt.typ) ? debt.meta.cislo_zmluvy : ""}`,
          subValue: debt.dti.value,
          cloudMeta: {
            [mortgageId]: {
              clouds:[{
                isActive: debt.is_active,
                invisible: false,
                id: debt.cloud,
                type_label: `Cloud výdavku ${translator.translateDebtType(debt.typ).toLowerCase()}`,
                label: debt.dti.value + "€",
                files: [],
              }]
            },
          },
          missingFiles: [],
        })
      }
      return clouds
    },
    constructMortgage(mortgage, mortgageId="_") {
      if (!mortgage) return [];
      return [{
        cloudId: mortgage.cloud,
        objectId: mortgage.id,
        label: "Dokladovanie účelu",
        image: require("@/images/dokladovanie_ucelu_avatar.png"),
        invisible: false,
        cloudMeta: {
          [mortgageId]: {
            clouds:[{
              invisible: false,
              isActive: true,
              type_label: "Cloud hypotéky",
              label: ``,
              id: mortgage.cloud,
              files: [],
            }]
          },
        },
        missingFiles: [],
      }]
    },
    constructApplications(applications, mortgageId="_") {
      if (applications.length === 0) return [];
      const clouds = [];
      for (const application of applications) {
        clouds.push({
          cloudId: application.cloud,
          objectId: application.id,
          objectData: {
            stage: application.stage,
          },
          invisible: true,
          cloudMeta: {
            [mortgageId]: {
              clouds:[{
                invisible: true,
                type_label: "Cloud žiadosti",
                label: `${application.bank.nazov}`,
                id: application.cloud,
                files: [],
              }]
            },
          },
          missingFiles: [],
        })
      }
      return clouds
    },
    constructProperties(properties, mortgageId="_", usedProperties=null) {
      if (properties.length === 0) return [];
      const clouds = [];
      for (const property of properties) {
        clouds.push({
          cloudId: property.cloud,
          objectId: property.id,
          label: "Nehnuteľnosť",
          image: require("@/images/property-broker-app-icon.png"),
          subField: true,
          subLabel: propertyFilter.methods.getAddressModified(property),
          subValue: property.hodnota,
          cloudMeta: {
            [mortgageId]: {
              clouds:[{
                isActive: usedProperties?.includes(property.id) ? usedProperties?.includes(property.id) : true,
                invisible: false,
                id: property.cloud,
                type_label: `Cloud nehnuteľnosti`,
                label: propertyFilter.methods.getAddressModified(property),
                files: [],
              }]
            },
          },
          missingFiles: [],
        })
      }
      return clouds
    },
    constructIncomes(incomes, mortgageId="_") {
      if (incomes.length === 0) return [];
      const translator = translateFromDBValue.methods;
      const clouds = []
      for (const income of incomes) {
        clouds.push({
          cloudId: income.cloud,
          objectId: income.id,
          label: "Príjem " + income.clientInfo.type.toLowerCase() + "a",
          image: require("@/images/incomeitemcloud.png"),
          subField: true,
          subLabel: translator.translateIncomeType(income.druh),
          subValue: income.dti.value,
          cloudMeta: {
            [mortgageId]: {
              clouds:[{
                isActive: income.is_active,
                invisible: false,
                id: income.cloud,
                type_label: `Cloud príjmu ${translator.translateIncomeType(income.druh).toLowerCase()}`,
                label: income.dti.value + "€",
                files: [],
              }]
            },
          },
          missingFiles: [],
        })
      }
      return clouds
    },
  }
}