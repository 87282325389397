import { render, staticRenderFns } from "./MortgageApplicationItemPicked.vue?vue&type=template&id=72c712c1&scoped=true&"
import script from "./MortgageApplicationItemPicked.vue?vue&type=script&lang=js&"
export * from "./MortgageApplicationItemPicked.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c712c1",
  null
  
)

export default component.exports